import { ThunkAction } from 'redux-thunk';
import { types } from '../../action/constants';
import ISetSchemesList from '../../action/scheme/ISetSchemesList';
import timeout from '../index/timeout';
import { getApiHost, getApiPort, getApiProtocol } from '../../util/env';

export default function getSchemeList(token: string): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/scheme/list`, {
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                    'X-AUTH-TOKEN': token
                },
                method: 'GET',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            }).then((result) => {
                const setSchemeList: ISetSchemesList = {
                    type: types.set_schemes_list,
                    schemesList: result
                };

                dispatch(setSchemeList);

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                resolve();
            }).catch((response: Response) => {
                if (response.status === 401) {
                    dispatch(timeout());
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}