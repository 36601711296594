import * as React from 'react';
import { Button, Form as AntForm, TreeSelect, Radio } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IReference from '../../../../model/scheme/IReference';
import IEntityType from '../../../../model/scheme/IEntityType';
import IEntity from "../../../../model/scheme/IEntity";

const RadioGroup = Radio.Group;

export interface IProps extends FormComponentProps {
    entityTypes: IEntityType[];
    onChange: (reference: IReference) => void;
    disabled: boolean;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Form extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, setFieldsValue} = this.props.form;
        let valueA: string[] = [];
        let valueB: string[] = [];
        let constraint: string = 'need';
        let operator: string = 'and';

        return (
            <AntForm onSubmit={this.onSubmit.bind(this)}>
                <AntForm.Item label={'Diese Entitäten'}>
                    {getFieldDecorator('a', {
                        initialValue: valueA,
                        rules: [{required: true, message: 'Bitte Entitäten-Typen oder Entitäten auswählen.'}],
                    })(
                        <TreeSelect
                            showSearch
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            allowClear
                            treeDefaultExpandAll
                            treeNodeFilterProp="title"
                            onChange={(value) => {
                                setFieldsValue({
                                    'a': value
                                })
                            }}
                            disabled={this.props.disabled}
                            multiple
                        >
                            {
                                this.props.entityTypes.map((entityType) => {
                                    return (
                                        <TreeSelect.TreeNode
                                            value={`entityType_${entityType.id}`}
                                            title={entityType.n}
                                            key={entityType.id}
                                        >
                                            {entityType.e.map((entity) => {
                                                return (
                                                    <TreeSelect.TreeNode
                                                        value={`entity_${entity.id}`}
                                                        title={entity.n}
                                                        key={entity.id}
                                                    />
                                                );
                                            })}
                                        </TreeSelect.TreeNode>
                                    );
                                })
                            }
                        </TreeSelect>
                    )}
                </AntForm.Item>
                <AntForm.Item>
                    {getFieldDecorator('constraint', {
                        initialValue: constraint,
                        rules: [{required: true, message: 'Bitte eine Bedingung auswählen.'}],
                    })(
                        <RadioGroup>
                            <Radio value="need">erfordern</Radio>
                            <Radio value="block">werden ausgeschlossen durch</Radio>
                        </RadioGroup>
                    )}
                </AntForm.Item>
                <AntForm.Item label={'diese Entitäten'}>
                    {getFieldDecorator('b', {
                        initialValue: valueB,
                        rules: [{required: true, message: 'Bitte Entitäten-Typen oder Entitäten auswählen.'}],
                    })(
                        <TreeSelect
                            showSearch
                            dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                            allowClear
                            treeDefaultExpandAll
                            treeNodeFilterProp="title"
                            onChange={(value) => {
                                setFieldsValue({
                                    'b': value
                                })
                            }}
                            disabled={this.props.disabled}
                            multiple
                        >
                            {
                                this.props.entityTypes.map((entityType) => {
                                    return (
                                        <TreeSelect.TreeNode
                                            value={`entityType_${entityType.id}`}
                                            title={entityType.n}
                                            key={entityType.id}
                                        >
                                            {entityType.e.map((entity) => {
                                                return (
                                                    <TreeSelect.TreeNode
                                                        value={`entity_${entity.id}`}
                                                        title={entity.n}
                                                        key={entity.id}
                                                    />
                                                );
                                            })}
                                        </TreeSelect.TreeNode>
                                    );
                                })
                            }
                        </TreeSelect>
                    )}
                </AntForm.Item>
                <AntForm.Item label={'Operator'}>
                    {getFieldDecorator('operator', {
                        initialValue: operator,
                        rules: [{required: true, message: 'Bitte einen Operator wählen.'}],
                    })(
                        <RadioGroup>
                            <Radio value="and">und</Radio>
                            <Radio value="or">oder</Radio>
                        </RadioGroup>
                    )}
                </AntForm.Item>
                <AntForm.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.props.disabled || hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </AntForm.Item>
            </AntForm>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                let reference: IReference;

                reference = {
                    id: null,
                    rEA: [],
                    rETA: [],
                    rEB: [],
                    rETB: [],
                    constraint: values.constraint,
                    operator: values.operator,
                };

                for (let indexA: number = 0; indexA < values.a.length; indexA++) {
                    const a = values.a[indexA].split('_');

                    if (a[0] === 'entity') {
                        reference.rEA.push(a[1]);
                    } else if (a[0] === 'entityType') {
                        reference.rETA.push(a[1]);
                    }
                }
                for (let indexB: number = 0; indexB < values.b.length; indexB++) {
                    const b = values.b[indexB].split('_');

                    if(b[0] === 'entity') {
                        reference.rEB.push(b[1]);
                    } else if (b[0] === 'entityType') {
                        reference.rETB.push(b[1]);
                    }
                }

                this.props.onChange(reference);
            }
        });
    }
}

export default AntForm.create()(Form);