import ISetAuth from './index/ISetAuth';
import ISetBusy from './index/ISetBusy';
import ISetSchemesList from './scheme/ISetSchemesList';
import ISetScheme from './scheme/ISetScheme';
import ISetUsers from './user/ISetUsers';
import ISetUser from './user/ISetUser';
import ISetConfigurations from './configuration/ISetConfigurations';
import ISetVisualsList from "./visual/ISetVisualsList";

export type actions = ISetAuth | ISetBusy | ISetScheme | ISetUsers | ISetUser | ISetConfigurations | ISetSchemesList | ISetVisualsList;

export enum types {
    set_auth = 'set_auth',
    set_schemes_list = 'set_schemes_list',
    set_busy = 'set_busy',
    set_scheme = 'set_scheme',
    set_users = 'set_users',
    set_user = 'set_user',
    set_configurations = 'set_configurations',
    set_visuals_list = 'set_visuals_list',
};
