import { assign } from 'lodash';
import IState from '../../state/IState';
import { actions, types } from '../../action/constants';

export default function reduce(state: IState, action: actions): IState {
    switch (action.type) {
        case types.set_configurations:
            return assign({}, state, {
                configurations: action.configurations
            });
        default:
            return state;
    }
};