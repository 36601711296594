import * as React from 'react';
import { assign } from 'lodash';
import { Button, Form as AntForm, Select, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IFile from '../../../../model/scheme/IFile';
import Upload from '../../../index/Upload';
import { getApiHost, getApiPort, getApiProtocol } from '../../../../util/env';

export interface IProps extends FormComponentProps {
    token: string;
    pdf: IFile;
    onChange: (pdf: IFile) => void;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Form extends React.Component<IProps, {}> {
    public render() {
        const {getFieldValue, getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;
        const nameError = getFieldError('n');
        const groupError = getFieldError('g');
        const fileError = getFieldError('f');

        return (
            <AntForm onSubmit={this.onSubmit.bind(this)}>
                <AntForm.Item
                    validateStatus={nameError ? 'error' : 'success'}
                    help={nameError || ''}
                    label={'Name'}
                >
                    {getFieldDecorator('n', {
                        initialValue: this.props.pdf.n,
                        rules: [{required: true, message: 'Bitte Name eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    n: e.target.value
                                })
                            }}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={fileError ? 'error' : 'success'}
                    help={fileError || ''}
                    label={'PDF'}
                >
                    {getFieldDecorator('f', {
                        initialValue: this.props.pdf.f,
                        rules: [{required: true, message: 'Bitte PDF hochladen.'}],
                    })(
                        <Upload
                            file={getFieldValue('f')}
                            onChange={(file) => {
                                setFieldsValue({
                                    f: file
                                })
                            }}
                            action={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/upload/create`}
                            headers={{
                                'X-AUTH-TOKEN': this.props.token
                            }}
                            name="file"
                            showUploadList={false}
                            accept="application/pdf"
                            disabled={false}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item label={'Tags'}>
                    {getFieldDecorator('t', {
                        initialValue: this.props.pdf.t

                    })(
                        <Select
                            mode="tags"
                            onChange={(value) => {
                                setFieldsValue({
                                    t: value
                                })
                            }}
                        ></Select>
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={groupError ? 'error' : 'success'}
                    help={groupError || ''}
                    label="Gruppe"
                >
                    {getFieldDecorator('g', {
                        initialValue: this.props.pdf.g,
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{required: true, message: 'Bitte Gruppe auswählen.'}],
                    })(
                        <Select onChange={(value: string) => {
                            setFieldsValue({
                                g: value
                            })
                        }}>
                            <Select.Option value="1">01 Standarddokumente</Select.Option>
                            <Select.Option value="2">02 Grundrisse EG</Select.Option>
                            <Select.Option value="3">03 Grundrisse DG/OG</Select.Option>
                            <Select.Option value="4">04 Grundrisse Dach</Select.Option>
                            <Select.Option value="5">05 Schnitte</Select.Option>
                            <Select.Option value="6">06 Ansichten</Select.Option>
                            <Select.Option value="7">07 Wohnflächenberechnungen</Select.Option>
                            <Select.Option value="8">08 Umbaute Räume</Select.Option>
                        </Select>
                    )}
                </AntForm.Item>
                <AntForm.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </AntForm.Item>
            </AntForm>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onChange(
                    assign({}, this.props.pdf, {
                        n: values.n,
                        t: values.t,
                        f: values.f,
                        g: values.g
                    })
                );
            }
        });
    }
}

export default AntForm.create()(Form);