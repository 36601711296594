import { ThunkAction } from 'redux-thunk';
import { types } from '../../action/constants';
import IUser from '../../model/user/IUser';

export default function setUser(user: IUser): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            dispatch({
                type: types.set_user,
                user
            });

            resolve();
        });
    }
}