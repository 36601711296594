import { assign, get } from 'lodash';
import { v1 } from 'uuid';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IScheme from '../../../model/scheme/IScheme';
import IReference from '../../../model/scheme/IReference';

export default function add(reference: IReference): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            let buffer = assign({}, scheme);
            let referenceBuffer = assign({}, reference, {id: `frontend-${v1()}`});

            buffer.references = buffer.references.concat(referenceBuffer);

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}