import * as React from 'react';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import { Table, Icon, Button, notification, Modal, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import IUser from '../../model/user/IUser';
import getUsers from '../../creator/user/getUsers';
import deleteUser from '../../creator/user/delete';

export interface IProps {
    users: IUser[];
    token: string;
    getUsers: (token: string) => void;
    deleteUser: (token: string, id: number) => void;
}

class List extends React.Component<IProps, {}> {
    public componentWillMount(): void {
        this.props.getUsers(
            this.props.token
        );
    }

    public renderHeader() {
        return (
            <h1>
                Benutzer
                    <Link to={'/user/form'}>
                        <Tooltip title="Neuen Nutzer anlegen">
                            <Button
                                size="large"
                                type="primary"
                                style={{float: 'right'}}
                            >
                                <Icon type="plus"/> neuer Nutzer
                            </Button>
                        </Tooltip>
                    </Link>
            </h1>
        );
    }

    public render() {
        const columns = [
            {
                title: 'Benutzer',
                dataIndex: 'userName'
            },
            {
                title: 'Vorname',
                dataIndex: 'firstName'
            },
            {
                title: 'Nachname',
                dataIndex: 'lastName'
            },
            {
                title: 'E-Mail',
                dataIndex: 'email'
            },
            {
                title: 'Rollen',
                key: 'roles',
                render: (text: string, user: IUser) => {
                    return (
                        <span>{user.roles.map((role: string) => {
                            switch (role) {
                                case 'ROLE_ADMIN':
                                    return 'Administrator';
                                case 'ROLE_USER':
                                    return 'Benutzer';
                            }
                        }).join(', ')}</span>
                    );
                }
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, user: IUser) => {
                    let buttons: React.ReactElement<any>[] = [];

                    buttons.push(
                        <Tooltip title="Löschen">
                            <Button
                                type="danger"
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Möchten Sie "${user.userName}" wirklich löschen?`,
                                        okText: 'ja',
                                        okType: 'danger',
                                        cancelText: 'nein',
                                        onOk: () => {
                                            this.props.deleteUser(
                                                this.props.token,
                                                user.id
                                            );
                                        }
                                    });
                                }}
                            >
                                <Icon type="delete"/>
                            </Button>
                        </Tooltip>
                    );

                    buttons.push(
                        <Tooltip title="Bearbeiten">
                            <Link to={`/user/form/${user.id}`}>
                                <Button>
                                    <Icon type="edit"/>
                                </Button>
                            </Link>
                        </Tooltip>
                    );

                    return (
                        <span>
                            {buttons.map((button, index) => {
                                return (
                                    <span key={index}>
                                        <span style={{float: 'right'}}>{button}</span>
                                        <span style={{float: 'right'}}>&nbsp;</span>
                                    </span>
                                );
                            })}
                        </span>
                    );
                }
            }
        ];

        return (
            <div>
                {this.renderHeader()}
                <Table dataSource={this.props.users.slice().reverse()} columns={columns} pagination={false}
                       rowKey="id"/>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        users: get(state, 'users', []),
        token: get(state, 'auth.token', null),
    } as Partial<IProps>),
    (dispatch) => ({
        getUsers: (token: string) => {
            dispatch(getUsers(token)).then(noop).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Benutzer konnten nicht geladen werden.'
                });
            });
        },
        deleteUser: (token: string, id: number) => {
            dispatch(deleteUser(token, id)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Benutzer wurde gelöscht.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Der Benutzer konnte nicht gelöscht werden.'
                });
            });
        },
    } as Partial<IProps>)
)(
    List
);
