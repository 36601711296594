import { assign, get } from 'lodash';
import { v1 } from 'uuid';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IScheme from '../../../model/scheme/IScheme';
import IFile from '../../../model/scheme/IFile';

export default function add(pdf: IFile): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            let buffer: IScheme = assign({}, scheme);

            buffer.pdfs.push(
                assign({}, pdf, {
                    id: `frontend-${v1()}`
                })
            );

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}