import * as React from 'react';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import { Tabs, Button, notification } from 'antd';
import * as isTabEnabled from '../../util/isTabEnabled';

import General from './form/general/Form';
import Entity from './form/entity/Index';
import Reference from './form/reference/Index';
import PriceList from './form/priceList/Index';
import Visual from './form/visual/Index';
import Layout from './form/layout/Form';
import Workflow from './form/workflow/Index';
import Pdf from './form/pdf/Index';

import IScheme from '../../model/scheme/IScheme';
import IGeneral from '../../model/scheme/IGeneral';
import IEntityType from '../../model/scheme/IEntityType';
import IEntity from '../../model/scheme/IEntity';
import IReference from '../../model/scheme/IReference';
import IPriceList from '../../model/scheme/IPriceList';
import IFile from '../../model/scheme/IFile';
import ILayout from '../../model/scheme/ILayout';
import IWorkflowStep from '../../model/scheme/IWorkflowStep';
import IWorkflowSubStep from '../../model/scheme/IWorkflowSubStep';

import editScheme from '../../creator/scheme/editScheme';
import createScheme from '../../creator/scheme/createScheme';

import addReference from '../../creator/scheme/reference/add';
import deleteReference from '../../creator/scheme/reference/delete';
import addEntityType from '../../creator/scheme/entity/addEntityType';
import deleteEntityType from '../../creator/scheme/entity/deleteEntityType';
import updateEntityType from '../../creator/scheme/entity/updateEntityType';
import addEntity from '../../creator/scheme/entity/addEntity';
import deleteEntity from '../../creator/scheme/entity/deleteEntity';
import updateEntity from '../../creator/scheme/entity/updateEntity';
import addPriceList from '../../creator/scheme/priceList/add';
import deletePriceList from '../../creator/scheme/priceList/delete';
import updatePriceList from '../../creator/scheme/priceList/update';
import addVisual from '../../creator/scheme/visual/add';
import deleteVisual from '../../creator/scheme/visual/delete';
import updateVisual from '../../creator/scheme/visual/update';
import addWorkflowStep from '../../creator/scheme/workflow/addStep';
import deleteWorkflowStep from '../../creator/scheme/workflow/deleteStep';
import updateWorkflowStep from '../../creator/scheme/workflow/updateStep';
import addWorkflowSubStep from '../../creator/scheme/workflow/addSubStep';
import moveWorkflowStepForward from '../../creator/scheme/workflow/moveStepForward';
import moveWorkflowStepBackward from '../../creator/scheme/workflow/moveStepBackward';
import deleteWorkflowSubStep from '../../creator/scheme/workflow/deleteSubStep';
import updateWorkflowSubStep from '../../creator/scheme/workflow/updateSubStep';
import moveWorkflowSubStepForward from '../../creator/scheme/workflow/moveSubStepForward';
import moveWorkflowSubStepBackward from '../../creator/scheme/workflow/moveSubStepBackward';
import addPdf from '../../creator/scheme/pdf/add';
import deletePdf from '../../creator/scheme/pdf/delete';
import updatePdf from '../../creator/scheme/pdf/update';

import setGeneral from '../../creator/scheme/general/set';
import setLayout from '../../creator/scheme/layout/set';
import setScheme from '../../creator/scheme/setScheme';

export interface IProps extends RouteComponentProps<{ id: string }> {
    scheme: IScheme;
    token?: string;

    setLayout?: (layout: ILayout) => void;
    setGeneral?: (general: IGeneral) => void;

    addEntityType?: (entityType: IEntityType) => void;
    deleteEntityType?: (entityType: IEntityType) => void;
    updateEntityType?: (entityType: IEntityType) => void;
    addEntity?: (entityType: IEntityType, entity: IEntity) => void;
    deleteEntity?: (entityType: IEntityType, entity: IEntity) => void;
    updateEntity?: (entityType: IEntityType, entity: IEntity) => void;
    addReference?: (reference: IReference) => void;
    deleteReference?: (reference: IReference) => void;
    addPriceList?: (priceList: IPriceList) => void;
    deletePriceList?: (priceList: IPriceList) => void;
    updatePriceList?: (priceList: IPriceList) => void;
    addVisual?: (visual: IFile) => void;
    deleteVisual?: (visual: IFile) => void;
    updateVisual?: (visual: IFile) => void;
    addWorkflowStep?: (workflowStep: IWorkflowStep) => void;
    deleteWorkflowStep?: (workflowStep: IWorkflowStep) => void;
    updateWorkflowStep?: (workflowStep: IWorkflowStep) => void;
    addWorkflowSubStep?: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => void;
    moveWorkflowStepForward?: (workflowStep: IWorkflowStep) => void;
    moveWorkflowStepBackward?: (workflowStep: IWorkflowStep) => void;
    deleteWorkflowSubStep?: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => void;
    updateWorkflowSubStep?: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => void;
    moveWorkflowSubStepForward?: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => void;
    moveWorkflowSubStepBackward?: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => void;
    addPdf?: (pdf: IFile) => void;
    deletePdf?: (pdf: IFile) => void;
    updatePdf?: (pdf: IFile) => void;

    save?: (token: string, scheme: IScheme) => void;
}

class Index extends React.Component<IProps, {}> {
    public render() {
        if(!this.props.scheme) {
            return null;
        }
        return (
            <div>
                <h1>
                    {this.props.scheme.name || <span>&nbsp;</span>}
                    <Button
                        disabled={!isTabEnabled.isEntityTabEnabled(this.props.scheme)}
                        size="large"
                        type="danger"
                        style={{float: 'right'}}
                        onClick={() => {
                            this.props.save(
                                this.props.token,
                                this.props.scheme
                            );
                        }}>
                        auf Server übertragen
                    </Button>
                </h1>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="allgemein" key="1"
                                  disabled={!isTabEnabled.isGeneralTabEnabled(this.props.scheme)}>
                        <General
                            scheme={this.props.scheme}
                            onChange={this.props.setGeneral}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Entitäten" key="2"
                                  disabled={!isTabEnabled.isEntityTabEnabled(this.props.scheme)}>
                        <Entity
                            token={this.props.token}
                            scheme={this.props.scheme}
                            onAddEntityType={this.props.addEntityType}
                            onUpdateEntityType={this.props.updateEntityType}
                            onDeleteEntityType={this.props.deleteEntityType}
                            onAddEntity={this.props.addEntity}
                            onUpdateEntity={this.props.updateEntity}
                            onDeleteEntity={this.props.deleteEntity}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Verknüpfungen" key="3"
                                  disabled={!isTabEnabled.isReferenceTabEnabled(this.props.scheme)}>
                        <Reference
                            scheme={this.props.scheme}
                            onAdd={this.props.addReference}
                            onDelete={this.props.deleteReference}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Preislisten" key="4"
                                  disabled={!isTabEnabled.isPriceListTabEnabled(this.props.scheme)}>
                        <PriceList
                            scheme={this.props.scheme}
                            onAdd={this.props.addPriceList}
                            onUpdate={this.props.updatePriceList}
                            onDelete={this.props.deletePriceList}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Visualisierungen" key="5"
                                  disabled={!isTabEnabled.isVisualTabEnabled(this.props.scheme)}>
                        <Visual
                            token={this.props.token}
                            scheme={this.props.scheme}
                            onAdd={this.props.addVisual}
                            onUpdate={this.props.updateVisual}
                            onDelete={this.props.deleteVisual}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Druckvorlage" key="6"
                                  disabled={!isTabEnabled.isLayoutTabEnabled(this.props.scheme)}>
                        <Layout
                            token={this.props.token}
                            scheme={this.props.scheme}
                            onChange={this.props.setLayout}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="PDFs" key="7"
                                  disabled={!isTabEnabled.isPdfTabEnabled(this.props.scheme)}>
                        <Pdf
                            token={this.props.token}
                            scheme={this.props.scheme}
                            onAdd={this.props.addPdf}
                            onUpdate={this.props.updatePdf}
                            onDelete={this.props.deletePdf}
                        />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Ablauf" key="8" disabled={!isTabEnabled.isWorkflowTabEnabled(this.props.scheme)}>
                        <Workflow
                            scheme={this.props.scheme}
                            onAddStep={this.props.addWorkflowStep}
                            onDeleteStep={this.props.deleteWorkflowStep}
                            onUpdateStep={this.props.updateWorkflowStep}
                            onMoveStepForward={this.props.moveWorkflowStepForward}
                            onMoveStepBackward={this.props.moveWorkflowStepBackward}
                            onAddSubStep={this.props.addWorkflowSubStep}
                            onDeleteSubStep={this.props.deleteWorkflowSubStep}
                            onUpdateSubStep={this.props.updateWorkflowSubStep}
                            onMoveSubStepForward={this.props.moveWorkflowSubStepForward}
                            onMoveSubStepBackward={this.props.moveWorkflowSubStepBackward}
                        />
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }
}

export default connect(
    (state, ownProps: IProps) => ({
        token: get(state, 'auth.token', null),
        scheme: get(state, 'scheme', null),
    } as Partial<IProps>),
    (dispatch, ownProps: IProps) => ({
        save: (token: string, scheme: IScheme) => {
            dispatch(scheme.id ? editScheme(token, scheme) : createScheme(token, scheme)).then(() => {
                dispatch(setScheme(null));

                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Das Scheme wurde auf den Server übertragen.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Das Scheme konnte nicht auf den Server übertragen werden.'
                });
            });
        },
        setGeneral: (general: IGeneral) => {
            dispatch(setGeneral(general)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die allgemeinen Eingaben wurden übernommen.'
                });
            }).catch(noop);
        },
        addEntityType: (entityType: IEntityType) => {
            dispatch(addEntityType(entityType)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Entitäten-Typ wurde angelegt.'
                });
            }).catch(noop);
        },
        addEntity: (entityType: IEntityType, entity: IEntity) => {
            dispatch(addEntity(entityType, entity)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Entität wurde angelegt.'
                });
            }).catch(noop);
        },
        updateEntityType: (entityType: IEntityType) => {
            dispatch(updateEntityType(entityType)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Entitäten-Typ wurde geändert.'
                });
            }).catch(noop);
        },
        updateEntity: (entityType: IEntityType, entity: IEntity) => {
            dispatch(updateEntity(entityType, entity)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Entität wurde geändert.'
                });
            }).catch(noop);
        },
        deleteEntityType: (entityType: IEntityType) => {
            dispatch(deleteEntityType(entityType)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Entitäten-Typ wurde gelöscht.'
                });
            }).catch(noop);
        },
        deleteEntity: (entityType: IEntityType, entity: IEntity) => {
            dispatch(deleteEntity(entityType, entity)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Entität wurde gelöscht.'
                });
            }).catch(noop);
        },
        addReference: (reference: IReference) => {
            dispatch(addReference(reference)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Verknüpfungen wurden angelegt.'
                });
            }).catch(noop);
        },
        deleteReference: (reference: IReference) => {
            dispatch(deleteReference(reference)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Verknüpfung wurde gelöscht.'
                });
            }).catch(noop);
        },
        addPriceList: (priceList: IPriceList) => {
            dispatch(addPriceList(priceList)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Preisliste wurde angelegt.'
                });
            }).catch(noop);
        },
        updatePriceList: (priceList: IPriceList) => {
            dispatch(updatePriceList(priceList)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Preisliste wurde geändert.'
                });
            }).catch(noop);
        },
        deletePriceList: (priceList: IPriceList) => {
            dispatch(deletePriceList(priceList)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Preisliste wurde gelöscht.'
                });
            }).catch(noop);
        },
        addVisual: (visual: IFile) => {
            dispatch(addVisual(visual)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Visualisierung wurde angelegt.'
                });
            }).catch(noop);
        },
        updateVisual: (visual: IFile) => {
            dispatch(updateVisual(visual)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Visualisierung wurde geändert.'
                });
            }).catch(noop);
        },
        deleteVisual: (visual: IFile) => {
            dispatch(deleteVisual(visual)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Visualisierung wurde gelöscht.'
                });
            }).catch(noop);
        },
        setLayout: (layout: ILayout) => {
            dispatch(setLayout(layout)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Layout-Eingaben wurde übernommen.'
                });
            }).catch(noop);
        },
        addWorkflowStep: (workflowStep: IWorkflowStep) => {
            dispatch(addWorkflowStep(workflowStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Schritt wurde angelegt.'
                });
            }).catch(noop);
        },
        deleteWorkflowStep: (workflowStep: IWorkflowStep) => {
            dispatch(deleteWorkflowStep(workflowStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Schritt wurde gelöscht.'
                });
            }).catch(noop);
        },
        updateWorkflowStep: (workflowStep: IWorkflowStep) => {
            dispatch(updateWorkflowStep(workflowStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Schritt wurde geändert.'
                });
            }).catch(noop);
        },
        moveWorkflowStepForward: (workflowStep: IWorkflowStep) => {
            dispatch(moveWorkflowStepForward(workflowStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Schritt wurde sortiert.'
                });
            }).catch(noop);
        },
        moveWorkflowStepBackward: (workflowStep: IWorkflowStep) => {
            dispatch(moveWorkflowStepBackward(workflowStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Schritt wurde sortiert.'
                });
            }).catch(noop);
        },
        addWorkflowSubStep: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => {
            dispatch(addWorkflowSubStep(workflowStep, workflowSubStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Unterschritt wurde angelegt.'
                });
            }).catch(noop);
        },
        deleteWorkflowSubStep: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => {
            dispatch(deleteWorkflowSubStep(workflowStep, workflowSubStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Unterschritt wurde gelöscht.'
                });
            }).catch(noop);
        },
        updateWorkflowSubStep: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => {
            dispatch(updateWorkflowSubStep(workflowStep, workflowSubStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Unterschritt wurde geändert.'
                });
            }).catch(noop);
        },
        moveWorkflowSubStepForward: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => {
            dispatch(moveWorkflowSubStepForward(workflowStep, workflowSubStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Unterschritt wurde sortiert.'
                });
            }).catch(noop);
        },
        moveWorkflowSubStepBackward: (workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep) => {
            dispatch(moveWorkflowSubStepBackward(workflowStep, workflowSubStep)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Der Unterschritt wurde sortiert.'
                });
            }).catch(noop);
        },
        addPdf: (pdf: IFile) => {
            dispatch(addPdf(pdf)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Das PDF wurde angelegt.'
                });
            }).catch(noop);
        },
        updatePdf: (pdf: IFile) => {
            dispatch(updatePdf(pdf)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Das PDF wurde geändert.'
                });
            }).catch(noop);
        },
        deletePdf: (pdf: IFile) => {
            dispatch(deletePdf(pdf)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Das PDF wurde gelöscht.'
                });
            }).catch(noop);
        },
    } as Partial<IProps>)
)(
    withRouter(Index)
);