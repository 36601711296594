import { ThunkAction } from 'redux-thunk';
import { omit } from 'lodash';
import { types } from '../../action/constants';
import IUser from '../../model/user/IUser';
import timeout from '../index/timeout';
import { getApiHost, getApiPort, getApiProtocol } from '../../util/env';

export default function createUser(token: string, user: IUser): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/user/register`, {
                body: JSON.stringify(
                    omit(user, ['id'])
                ),
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                    'X-AUTH-TOKEN': token
                },
                method: 'POST',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                resolve();
            }).catch((response: Response) => {
                if (response.status === 401) {
                    dispatch(timeout());
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}