import * as React from 'react';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import { Table, Icon, Button, notification, Modal, Tooltip } from 'antd';
import getVisualsList from '../../creator/visual/getVisuals';
import deleteVisual from '../../creator/visual/delete';
import IVisual from "../../model/visual/IVisual";

export interface IProps {
    visuals: IVisual[];
    token: string;
    getVisualsList: (token: string) => void;
    deleteVisual: (token: string, id: string) => void;
}

class List extends React.Component<IProps, {}> {
    public componentWillMount(): void {
        this.props.getVisualsList(
            this.props.token
        );
    }

    public renderHeader() {
        return (
            <h1>
                Visualisierungen
            </h1>
        );
    }

    public render() {
        const columns = [
            {
                title: 'Code',
                dataIndex: 'code'
            },
            {
                title: 'Bauherr 1 Nachname',
                dataIndex: 'lastName1'
            },
            {
                title: 'Bauherr 1 Vorname',
                dataIndex: 'firstName1'
            },
            {
                title: 'Bauherr 2 Nachname',
                dataIndex: 'lastName2'
            },
            {
                title: 'Bauherr 2 Vorname',
                dataIndex: 'firstName2'
            },
            {
                title: 'erzeugt am',
                dataIndex: 'createdAt'
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, visual: IVisual) => {
                    let buttons: React.ReactElement<any>[] = [];

                    buttons.push(
                        <Tooltip title="Löschen">
                            <Button
                                type="danger"
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Möchten Sie "${visual.code}" wirklich löschen?`,
                                        okText: 'ja',
                                        okType: 'danger',
                                        cancelText: 'nein',
                                        onOk: () => {
                                            this.props.deleteVisual(
                                                this.props.token,
                                                visual.id
                                            );
                                        }
                                    });
                                }}
                            >
                                <Icon type="delete"/>
                            </Button>
                        </Tooltip>
                    );

                    return (
                        <span>
                            {buttons.map((button, index) => {
                                return (
                                    <span key={index}>
                                        <span style={{float: 'right'}}>{button}</span>
                                        <span style={{float: 'right'}}>&nbsp;</span>
                                    </span>
                                );
                            })}
                        </span>
                    );
                }
            }
        ];

        return (
            <div>
                {this.renderHeader()}
                <Table dataSource={this.props.visuals.slice().reverse()} columns={columns} pagination={false}
                       rowKey="id"/>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        visuals: get(state, 'visuals', []),
        token: get(state, 'auth.token', null),
    } as Partial<IProps>),
    (dispatch) => ({
        getVisualsList: (token: string) => {
            dispatch(getVisualsList(token)).then(noop).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Visualisierungen konnten nicht geladen werden.'
                });
            });
        },
        deleteVisual: (token: string, id: string) => {
            dispatch(deleteVisual(token, id)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Visualisierung wurde gelöscht.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Visualisierung konnte nicht gelöscht werden.'
                });
            });
        },
    } as Partial<IProps>)
)(
    List
);