import * as React from 'react';
import { noop } from 'lodash';
import { Button, Table, Icon, Modal } from 'antd';
import IScheme from '../../../../model/scheme/IScheme';
import Form from './Form';
import IPriceList from '../../../../model/scheme/IPriceList';

export interface IProps {
    scheme: IScheme;
    onAdd: (priceList: IPriceList) => void;
    onUpdate: (priceList: IPriceList) => void;
    onDelete: (priceList: IPriceList) => void;
}

export interface IState {
    priceList: IPriceList;
}

class Index extends React.Component<IProps, IState> {
    constructor(props: IProps, context: {}) {
        super(props, context);

        this.state = {
            priceList: null,
        };
    }

    public renderForm() {
        return this.state.priceList && (
            <Modal
                title={this.state.priceList.name ?
                    `${this.state.priceList.name} bearbeiten` :
                    'Preisliste erstellen'
                }
                visible={true}
                onOk={noop}
                onCancel={() => {
                    this.setState({
                        priceList: null
                    });
                }}
                closable={true}
                destroyOnClose={true}
                footer={null}
                width={1040}
            >
                <Form
                    priceList={this.state.priceList}
                    entityTypes={this.props.scheme.entityTypes}
                    onChange={(priceList) => {
                        if (priceList.id) {
                            this.props.onUpdate(priceList);
                        } else {
                            this.props.onAdd(priceList);
                        }

                        this.setState({
                            priceList: null
                        });
                    }}
                />
            </Modal>
        );
    }

    public renderTableHeader() {
        return (
            <h2>
                Preislisten
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            priceList: {
                                id: null,
                                name: null,
                                rET: null,
                                prices: [],
                                roles: []
                            }
                        })
                    }}
                    style={{float: 'right'}}
                >
                    <Icon type="plus"/>
                </Button>
            </h2>
        );
    }

    public renderTable() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name',
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, priceList: IPriceList) => {
                    return (
                        <span>
                            <Button
                                type="danger"
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Möchten Sie "${priceList.name}" wirklich löschen?`,
                                        okText: 'ja',
                                        okType: 'danger',
                                        cancelText: 'nein',
                                        onOk: () => {
                                            this.props.onDelete(priceList);
                                        }
                                    });
                                }}
                                style={{float: 'right'}}
                            >
                                <Icon type="delete"/>
                            </Button>
                            <span style={{float: 'right'}}>&nbsp;</span>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        priceList
                                    });
                                }}
                                style={{float: 'right'}}
                            >
                                <Icon type="edit"/>
                            </Button>
                        </span>
                    );
                }
            }
        ];

        return (
            <Table rowKey="id" dataSource={this.props.scheme.priceLists.slice().reverse()} columns={columns}
                   pagination={false}
            />
        );
    }

    public render() {
        return (
            <div>
                {this.renderForm()}
                {this.renderTableHeader()}
                {this.renderTable()}
            </div>
        );
    }
}

export default Index;