import * as React from 'react';
import { noop } from 'lodash';
import { Button, Table, Icon, Modal, Avatar } from 'antd';
import IScheme from '../../../../model/scheme/IScheme';
import Form from './Form';
import IFile from '../../../../model/scheme/IFile';
import { getApiHost, getApiPort, getApiProtocol } from '../../../../util/env';

export interface IProps {
    token: string;
    scheme: IScheme;
    onAdd: (visual: IFile) => void;
    onUpdate: (visual: IFile) => void;
    onDelete: (visual: IFile) => void;
}

export interface IState {
    file: IFile;
}

class Index extends React.Component<IProps, IState> {
    constructor(props: IProps, context: {}) {
        super(props, context);

        this.state = {
            file: null,
        };
    }

    public renderForm() {
        return this.state.file && (
            <Modal
                title={this.state.file.n ?
                    `${this.state.file.n} bearbeiten` :
                    'Visualisierung erstellen'
                }
                visible={true}
                onOk={noop}
                onCancel={() => {
                    this.setState({
                        file: null
                    });
                }}
                closable={true}
                destroyOnClose={true}
                footer={null}
                width={1040}
            >
                <Form
                    token={this.props.token}
                    visual={this.state.file}
                    onChange={(visual) => {
                        if (visual.id) {
                            this.props.onUpdate(visual);
                        } else {
                            this.props.onAdd(visual);
                        }

                        this.setState({
                            file: null
                        });
                    }}
                />
            </Modal>
        );
    }

    public renderTableHeader() {
        return (
            <h2>
                Visualisierungen
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            file: {
                                id: null,
                                n: null,
                                f: null,
                                g: null,
                                t: []
                            }
                        })
                    }}
                    style={{float: 'right'}}
                >
                    <Icon type="plus"/>
                </Button>
            </h2>
        );
    }

    public renderTable() {
        const columns = [
            {
                title: 'Vorschau',
                key: 'preview',
                render: (text: string, record: IFile) => {
                    return record.f ? (
                        <Avatar src={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/uploads/${record.f}`}
                                size="large"/>
                    ) : null;
                }
            },
            {
                title: 'Name',
                dataIndex: 'n',
            },
            {
                title: 'Tags',
                key: 't',
                render: (text: string, record: IFile) => {
                    return record.t.join(', ');
                }
            },
            {
                title: 'Gruppe',
                dataIndex: 'g',
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, file: IFile) => {
                    return (
                        <span>
                            <Button
                                type="danger"
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Möchten Sie diese Visualisierung wirklich löschen?`,
                                        okText: 'ja',
                                        okType: 'danger',
                                        cancelText: 'nein',
                                        onOk: () => {
                                            this.props.onDelete(file);
                                        }
                                    });
                                }}
                                style={{float: 'right'}}
                            >
                                <Icon type="delete"/>
                            </Button>
                            <span style={{float: 'right'}}>&nbsp;</span>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        file
                                    });
                                }}
                                style={{float: 'right'}}
                            >
                                <Icon type="edit"/>
                            </Button>
                        </span>
                    );
                }
            },
        ];

        return (
            <Table rowKey="id" dataSource={this.props.scheme.visuals.slice().reverse()} columns={columns}
                   pagination={false}
            />
        );
    }

    public render() {
        return (
            <div>
                {this.renderForm()}
                {this.renderTableHeader()}
                {this.renderTable()}
            </div>
        );
    }
}

export default Index;