import { isString } from 'lodash';
import IScheme from '../model/scheme/IScheme';

export function isGeneralTabEnabled(scheme: IScheme): boolean {
    return true;
};

export function isEntityTabEnabled(scheme: IScheme): boolean {
    return isString(scheme.name) && isString(scheme.app);
};

export function isReferenceTabEnabled(scheme: IScheme): boolean {
    return scheme.entityTypes.length > 0;
};

export function isPriceListTabEnabled(scheme: IScheme): boolean {
    return scheme.entityTypes.length > 0;
};

export function isVisualTabEnabled(scheme: IScheme): boolean {
    return true;
};

export function isLayoutTabEnabled(scheme: IScheme): boolean {
    return true;
};

export function isPdfTabEnabled(scheme: IScheme): boolean {
    return true;
};

export function isWorkflowTabEnabled(scheme: IScheme): boolean {
    return scheme.entityTypes.length > 0;
};