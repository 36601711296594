import * as React from 'react';
import { assign } from 'lodash';
import { Button, Form as AntForm, Input, Switch, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IWorkflowSubStep from '../../../../model/scheme/IWorkflowSubStep';
import IEntityType from '../../../../model/scheme/IEntityType';

export interface IProps extends FormComponentProps {
    subStep: IWorkflowSubStep;
    entityTypes: IEntityType[];
    onChange: (subStep: IWorkflowSubStep) => void;
    disabled: boolean;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class SubStepForm extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;
        const nameError = getFieldError('n');
        const descriptionError = getFieldError('desc');
        const relatedEntityTypeError = getFieldError('rET');
        const typeError = getFieldError('t');
        const displayInvalidOptionsError = getFieldError('dIO');
        const multipleError = getFieldError('multi');
        const requiredError = getFieldError('req');

        return (
            <AntForm onSubmit={this.onSubmit.bind(this)}>
                <AntForm.Item
                    validateStatus={nameError ? 'error' : 'success'}
                    help={nameError || ''}
                    label={'Name'}
                >
                    {getFieldDecorator('n', {
                        initialValue: this.props.subStep.n,
                        rules: [{required: true, message: 'Bitte Name eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    n: e.target.value
                                })
                            }}
                            disabled={this.props.disabled}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={descriptionError ? 'error' : 'success'}
                    help={descriptionError || ''}
                    label={'Beschreibung / Frage'}
                >
                    {getFieldDecorator('desc', {
                        initialValue: this.props.subStep.desc,
                        rules: [{required: true, message: 'Bitte Beschreibung / Frage eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    desc: e.target.value
                                })
                            }}
                            disabled={this.props.disabled}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={relatedEntityTypeError ? 'error' : 'success'}
                    help={relatedEntityTypeError || ''}
                    label={'Zur Auswahl stehende Entitäten des Entitäten-Typs'}
                >
                    {getFieldDecorator('rET', {
                        initialValue: this.props.subStep.rET,
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{required: true, message: 'Bitte Typ auswählen.'}],
                    })(
                        <Select
                            onChange={(value: string) => {
                                setFieldsValue({
                                    rET: value
                                })
                            }}
                            disabled={this.props.disabled}
                        >{this.props.entityTypes.map((entityType) => {
                            return (
                                <Select.Option
                                    key={entityType.id}
                                    value={entityType.id}
                                >{entityType.n}</Select.Option>
                            );
                        })}</Select>
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={typeError ? 'error' : 'success'}
                    help={typeError || ''}
                    label={'Darstellung der Entitäten'}
                >
                    {getFieldDecorator('t', {
                        initialValue: this.props.subStep.t,
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{required: true, message: 'Bitte Typ auswählen.'}],
                    })(
                        <Select
                            onChange={(value: string) => {
                                setFieldsValue({
                                    t: value
                                })
                            }}
                            disabled={this.props.disabled}
                        >
                            <Select.Option value="tiles">Kachelansicht</Select.Option>
                            <Select.Option value="list">Listenansicht</Select.Option>
                        </Select>
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={displayInvalidOptionsError ? 'error' : 'success'}
                    help={displayInvalidOptionsError || ''}
                    label={'Nicht zutreffende Entitäten zeigen'}
                >
                    {getFieldDecorator('dIO', {
                        initialValue: this.props.subStep.dIO,
                    })(
                        <Switch
                            defaultChecked={this.props.subStep.dIO}
                            onChange={(displayInvalidOptions) => {
                                setFieldsValue({
                                    dIO: displayInvalidOptions
                                })
                            }}
                            disabled={this.props.disabled}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={multipleError ? 'error' : 'success'}
                    help={multipleError || ''}
                    label={'Mehrfachauswahl'}
                >
                    {getFieldDecorator('multi', {
                        initialValue: this.props.subStep.multi,
                    })(
                        <Switch
                            defaultChecked={this.props.subStep.multi}
                            onChange={(multiple) => {
                                setFieldsValue({
                                    multi: multiple
                                })
                            }}
                            disabled={this.props.disabled}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={requiredError ? 'error' : 'success'}
                    help={requiredError || ''}
                    label={'Muss ausgewählt werden'}
                >
                    {getFieldDecorator('req', {
                        initialValue: this.props.subStep.req,
                    })(
                        <Switch
                            defaultChecked={this.props.subStep.req}
                            onChange={(required) => {
                                setFieldsValue({
                                    req: required
                                })
                            }}
                            disabled={this.props.disabled}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.props.disabled || hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </AntForm.Item>
            </AntForm>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onChange(
                    assign({}, this.props.subStep, {
                        n: values.n,
                        desc: values.desc,
                        rET: values.rET,
                        t: values.t,
                        dIO: values.dIO,
                        multi: values.multi,
                        req: values.req,
                    })
                );
            }
        });
    }
}

export default AntForm.create()(SubStepForm);
