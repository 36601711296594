import { assign, get, findIndex } from 'lodash';
import { v1 } from 'uuid';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IScheme from '../../../model/scheme/IScheme';
import IEntity from '../../../model/scheme/IEntity';
import IEntityType from '../../../model/scheme/IEntityType';

export default function addEntity(entityType: IEntityType, entity: IEntity): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');
            const index = findIndex(scheme.entityTypes, {
                id: entityType.id
            });

            let buffer = assign({}, scheme);

            buffer.entityTypes[index].e.push(
                assign({}, entity, {
                    id: `frontend-${v1()}`
                })
            );

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}