import * as React from 'react';
import { assign, filter, concat } from 'lodash';
import { v1 } from 'uuid';
import { Input, Button, Form, Card, Icon, Select, Row, Col, Modal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IEntityType from '../../../../model/scheme/IEntityType';
import IEntityField from '../../../../model/scheme/IEntityField';

export interface IProps extends FormComponentProps {
    entityType: IEntityType;
    onChange: (entityType: IEntityType) => void;
    disabled: boolean;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class EntityTypeForm extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue, getFieldValue} = this.props.form;

        const nameError = getFieldError('n');

        getFieldDecorator('eF', {
            initialValue: this.props.entityType.eF
        });

        const entityFields: IEntityField[] = getFieldValue('eF');

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Item
                    validateStatus={nameError ? 'error' : 'success'}
                    help={nameError || ''}
                    label={'Name'}
                >
                    {getFieldDecorator('n', {
                        initialValue: this.props.entityType.n,
                        rules: [{required: true, message: 'Bitte Name eingeben.'}],

                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    n: e.target.value
                                })
                            }}
                            disabled={this.props.disabled}
                        />
                    )}
                </Form.Item>
                <div className="ant-form-item-label">
                    <label>Felder</label>
                </div>
                <Card>
                    {entityFields.map((entityField: IEntityField, index) => {
                        const nameField = `entityField_${entityField.id}_name`;
                        const nameFieldError = getFieldError(nameField);
                        const typeField = `entityField_${entityField.id}_type`;
                        const typeFieldError = getFieldError(typeField);

                        return (
                            <Row key={entityField.id} gutter={8}>
                                <Col span={10}>
                                    <Form.Item
                                        validateStatus={nameFieldError ? 'error' : 'success'}
                                        help={nameFieldError || ''}
                                    >
                                        {getFieldDecorator(nameField, {
                                            initialValue: entityField.n,
                                            validateTrigger: ['onChange', 'onBlur'],
                                            rules: [{required: true, message: 'Bitte Name eingeben.'}],
                                        })(
                                            <Input
                                                placeholder="Name"
                                                onChange={(e) => {
                                                    setFieldsValue({
                                                        [nameField]: e.target.value
                                                    })
                                                }}
                                                disabled={this.props.disabled}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={10}>
                                    <Form.Item
                                        validateStatus={typeFieldError ? 'error' : 'success'}
                                        help={typeFieldError || ''}
                                    >
                                        {getFieldDecorator(typeField, {
                                            initialValue: entityField.t,
                                            validateTrigger: ['onChange', 'onBlur'],
                                            rules: [{required: true, message: 'Bitte Typ auswählen.'}],
                                        })(
                                            <Select
                                                onChange={(value: string) => {
                                                    setFieldsValue({
                                                        [typeField]: value
                                                    })
                                                }}
                                                disabled={this.props.disabled}
                                            >
                                                <Select.Option value="text">Text</Select.Option>
                                                <Select.Option value="number">Zahl</Select.Option>
                                                <Select.Option value="imageUpload">Bild-Upload</Select.Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={4}>
                                    <Button
                                        type="danger"
                                        style={{marginTop: '3px'}}
                                        onClick={() => {
                                            Modal.confirm({
                                                title: `Möchten Sie dieses Feld wirklich löschen?`,
                                                okText: 'ja',
                                                okType: 'danger',
                                                cancelText: 'nein',
                                                onOk: () => {
                                                    this.removeEntityField(entityField);
                                                }
                                            });
                                        }}
                                        disabled={this.props.disabled}
                                    >
                                        <Icon type="delete"/>
                                    </Button>
                                </Col>
                            </Row>
                        );
                    })}
                    <Button size="small" onClick={this.addEntityField.bind(this)} disabled={this.props.disabled}>
                        <Icon type="plus"/>
                    </Button>
                </Card>
                <br/>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.props.disabled || hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                let entityFields = [];

                for (let i = 0; i < values.eF.length; i++) {
                    let id = values.eF[i].id;

                    entityFields.push({
                        id,
                        n: values[`entityField_${id}_name`],
                        t: values[`entityField_${id}_type`],
                    });
                }

                this.props.onChange(
                    assign({}, this.props.entityType, {
                        n: values.n,
                        eF: entityFields
                    })
                );
            }
        });
    }

    protected removeEntityField(entityField: IEntityField): void {
        const {form} = this.props;
        const entityFields: IEntityField[] = form.getFieldValue('eF');

        form.setFieldsValue({
            eF: filter(entityFields, (item) => {
                return item.id !== entityField.id;
            })
        });

        setTimeout(() => {
            form.validateFields();
        }, 0);
    }

    protected addEntityField(): void {
        const {form} = this.props;
        const entityFields: IEntityField[] = form.getFieldValue('eF');

        form.setFieldsValue({
            eF: concat(entityFields, {
                id: `frontend-${v1()}`,
                n: null,
                t: null
            })
        });
    }
}

export default Form.create()(EntityTypeForm);