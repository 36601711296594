import { assign } from 'lodash';
import IState from '../../state/IState';
import { actions, types } from '../../action/constants';

export default function reduce(state: IState, action: actions): IState {
    switch (action.type) {
        case types.set_scheme:
            return assign({}, state, {
                scheme: action.scheme
            });
        case types.set_schemes_list:
            return assign({}, state, {
                schemesList: action.schemesList
            });
        default:
            return state;
    }
};
