import { ThunkAction } from 'redux-thunk';
import ISetAuth from '../../action/index/ISetAuth';
import { types } from '../../action/constants';
import { getApiHost, getApiPort, getApiProtocol } from '../../util/env';

export default function login(userName: string, password: string): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise<void>((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/token`, {
                body: JSON.stringify({userName, password}),
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                },
                method: 'POST',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                return response.json();
            }).then((result) => {
                const setAuth: ISetAuth = {
                    type: types.set_auth,
                    auth: {
                        token: result.token.id,
                        tokenExpires: result.token.expiresAt,
                        anonymous: false,
                        id: result.user.id,
                        userName: result.user.userName,
                        firstName: result.user.firstName,
                        lastName: result.user.lastName,
                        email: result.user.email,
                        roles: result.user.roles,
                        isTimedOut: false
                    }
                };

                dispatch(setAuth);

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                resolve();
            }).catch((response: Response) => {
                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}