import { assign, get, findIndex } from 'lodash';
import { v1 } from 'uuid';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IScheme from '../../../model/scheme/IScheme';
import IWorkflowSubStep from '../../../model/scheme/IWorkflowSubStep';
import IWorkflowStep from '../../../model/scheme/IWorkflowStep';

export default function addSubStep(workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');
            const index = findIndex(scheme.workflowSteps, {
                id: workflowStep.id
            });

            let buffer = assign({}, scheme);

            buffer.workflowSteps[index].sSteps.push(
                assign({}, workflowSubStep, {
                    id: `frontend-${v1()}`
                })
            );

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}