import { assign, get, findIndex } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IScheme from '../../../model/scheme/IScheme';
import IWorkflowStep from '../../../model/scheme/IWorkflowStep';

export default function updateStep(workflowStep: IWorkflowStep): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            const index = findIndex(scheme.workflowSteps, {
                id: workflowStep.id
            });

            let buffer: IScheme = assign({}, scheme);

            buffer.workflowSteps[index] = workflowStep;

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}