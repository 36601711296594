import { ThunkAction } from 'redux-thunk';
import ISetAuth from '../../action/index/ISetAuth';
import { types } from '../../action/constants';

export default function logout(): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise<void>((resolve) => {
            const setAuth: ISetAuth = {
                type: types.set_auth,
                auth: null
            };

            dispatch(setAuth);

            resolve();
        });
    }
}