import { assign, get, findIndex, filter } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IScheme from '../../../model/scheme/IScheme';
import IEntity from '../../../model/scheme/IEntity';
import cleanupEntityReferences from '../cleanupEntityReferences';
import IEntityType from '../../../model/scheme/IEntityType';

export default function deleteEntity(entityType: IEntityType, entity: IEntity): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            const entityTypeIndex = findIndex(scheme.entityTypes, {
                id: entityType.id
            });

            let buffer: IScheme = assign({}, scheme);

            buffer.entityTypes[entityTypeIndex].e = filter(buffer.entityTypes[entityTypeIndex].e, (item) => {
                return item.id !== entity.id;
            });

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            dispatch(cleanupEntityReferences());

            resolve();
        });
    }
}