import * as React from 'react';
import { Button, Row, Col, Table, Icon, Modal } from 'antd';
import { noop } from 'lodash';
import IScheme from '../../../../model/scheme/IScheme';
import StepForm from './StepForm';
import SubStepForm from './SubStepForm';
import IWorkflowStep from '../../../../model/scheme/IWorkflowStep';
import IWorkflowSubStep from '../../../../model/scheme/IWorkflowSubStep';

export interface IProps {
    scheme: IScheme;
    onAddStep: (step: IWorkflowStep) => void;
    onUpdateStep: (step: IWorkflowStep) => void;
    onDeleteStep: (step: IWorkflowStep) => void;
    onMoveStepForward: (step: IWorkflowStep) => void;
    onMoveStepBackward: (step: IWorkflowStep) => void;
    onAddSubStep: (step: IWorkflowStep, subStep: IWorkflowSubStep) => void;
    onUpdateSubStep: (step: IWorkflowStep, subStep: IWorkflowSubStep) => void;
    onDeleteSubStep: (step: IWorkflowStep, entity: IWorkflowSubStep) => void;
    onMoveSubStepForward: (step: IWorkflowStep, entity: IWorkflowSubStep) => void;
    onMoveSubStepBackward: (step: IWorkflowStep, entity: IWorkflowSubStep) => void;
}

export interface IState {
    stepFormVisible: boolean;
    subStepFormVisible: boolean;
    step: IWorkflowStep;
    subStep: IWorkflowSubStep;
}

class Index extends React.Component<IProps, IState> {
    constructor(props: IProps, context: {}) {
        super(props, context);

        this.state = {
            stepFormVisible: false,
            subStepFormVisible: false,
            step: null,
            subStep: null
        };
    }

    public renderStepForm() {
        return this.state.step && this.state.stepFormVisible && (
            <Modal
                title={this.state.step.name ?
                    `${this.state.step.name} bearbeiten` :
                    'Schritt erstellen'
                }
                visible={true}
                onOk={noop}
                onCancel={() => {
                    this.setState({
                        stepFormVisible: false,
                        step: null
                    });
                }}
                closable={true}
                destroyOnClose={true}
                footer={null}
            >
                <StepForm
                    step={this.state.step}
                    onChange={(step) => {
                        if (step.id) {
                            this.props.onUpdateStep(step);
                        } else {
                            this.props.onAddStep(step);
                        }

                        this.setState({
                            stepFormVisible: false,
                            step: null
                        });
                    }}
                    disabled={!!this.props.scheme.activatedAt}
                />
            </Modal>
        );
    }

    public renderSubStepForm() {
        return this.state.subStep && this.state.subStepFormVisible && (
            <Modal
                title={this.state.subStep.n ?
                    `${this.state.subStep.n} bearbeiten` :
                    'Unterschritt erstellen'
                }
                visible={true}
                onOk={noop}
                onCancel={() => {
                    this.setState({
                        subStepFormVisible: false
                    });
                }}
                closable={true}
                destroyOnClose={true}
                footer={null}
            >
                <SubStepForm
                    subStep={this.state.subStep}
                    entityTypes={this.props.scheme.entityTypes}
                    onChange={(subStep) => {
                        if (subStep.id) {
                            this.props.onUpdateSubStep(this.state.step, subStep);
                        } else {
                            this.props.onAddSubStep(this.state.step, subStep);
                        }

                        this.setState({
                            subStepFormVisible: false,
                            subStep
                        });
                    }}
                    disabled={!!this.props.scheme.activatedAt}
                />
            </Modal>
        );
    }

    public renderStepTableHeader() {
        return (
            <h2>
                Schritte
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            stepFormVisible: true,
                            step: {
                                id: null,
                                name: null,
                                desc: null,
                                sSteps: []
                            }
                        })
                    }}
                    style={{float: 'right'}}
                    disabled={!!this.props.scheme.activatedAt}
                >
                    <Icon type="plus"/>
                </Button>
            </h2>
        );
    }

    public renderStepTable() {
        const entityTypeColumns = [
            {
                title: 'Name',
                dataIndex: 'name',
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, step: IWorkflowStep, index: number) => {
                    let buttons: React.ReactElement<any>[] = [];

                    buttons.push(
                        <Button
                            type="danger"
                            onClick={() => {
                                Modal.confirm({
                                    title: `Möchten Sie "${step.name}" wirklich löschen?`,
                                    okText: 'ja',
                                    okType: 'danger',
                                    cancelText: 'nein',
                                    onOk: () => {
                                        this.props.onDeleteStep(step);

                                        if (this.state.step && this.state.step.id === step.id) {
                                            this.setState({
                                                step: null
                                            });
                                        }
                                    }
                                });
                            }}
                            disabled={!!this.props.scheme.activatedAt}
                        >
                            <Icon type="delete"/>
                        </Button>
                    );

                    buttons.push(
                        <Button
                            onClick={() => {
                                this.setState({
                                    stepFormVisible: true,
                                    step,
                                });
                            }}
                        >
                            <Icon type="edit"/>
                        </Button>
                    );

                    if ((index + 1 ) < this.props.scheme.workflowSteps.length) {
                        buttons.push(
                            <Button
                                onClick={() => {
                                    this.props.onMoveStepBackward(
                                        step,
                                    );
                                }}
                                disabled={!!this.props.scheme.activatedAt}
                            >
                                <Icon type="down"/>
                            </Button>
                        );
                    }

                    if (index > 0) {
                        buttons.push(
                            <Button
                                onClick={() => {
                                    this.props.onMoveStepForward(
                                        step
                                    );
                                }}
                                disabled={!!this.props.scheme.activatedAt}
                            >
                                <Icon type="up"/>
                            </Button>
                        );
                    }

                    return (
                        <span>
                            {buttons.map((button, index) => {
                                return (
                                    <span key={index}>
                                        <span style={{float: 'right'}}>{button}</span>
                                        <span style={{float: 'right'}}>&nbsp;</span>
                                    </span>
                                );
                            })}
                        </span>
                    );
                }
            }
        ];

        return (
            <Table rowKey="id"
                   dataSource={this.props.scheme.workflowSteps}
                   columns={entityTypeColumns}
                   pagination={false}
                   rowSelection={{
                       selectedRowKeys: this.state.step ? [this.state.step.id] : [],
                       type: 'radio',
                       onSelect: (step: IWorkflowStep) => {
                           this.setState({
                               step
                           });
                       }
                   }}
            />
        );
    }

    public renderSubStepTableHeader() {
        return this.state.step && (
            <h2>
                Unterschritte
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            subStepFormVisible: true,
                            subStep: {
                                id: null,
                                n: null,
                                desc: null,
                                rET: null,
                                t: null,
                                dIO: false,
                                multi: false,
                                req: true,
                            }
                        })
                    }}
                    style={{float: 'right'}}
                    disabled={!!this.props.scheme.activatedAt}
                >
                    <Icon type="plus"/>
                </Button>
            </h2>
        );
    }

    public renderSubStepTable() {
        const entityColumns = [
            {
                title: 'Name',
                dataIndex: 'n',
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, subStep: IWorkflowSubStep, index: number) => {
                    let buttons: React.ReactElement<any>[] = [];

                    buttons.push(
                        <Button
                            type="danger"
                            onClick={() => {
                                Modal.confirm({
                                    title: `Möchten Sie "${subStep.n}" wirklich löschen?`,
                                    okText: 'ja',
                                    okType: 'danger',
                                    cancelText: 'nein',
                                    onOk: () => {
                                        this.props.onDeleteSubStep(this.state.step, subStep);

                                        if (this.state.subStep && this.state.subStep.id === subStep.id) {
                                            this.setState({
                                                subStep: null
                                            });
                                        }
                                    }
                                });
                            }}
                            disabled={!!this.props.scheme.activatedAt}
                        >
                            <Icon type="delete"/>
                        </Button>
                    );

                    buttons.push(
                        <Button
                            onClick={() => {
                                this.setState({
                                    subStepFormVisible: true,
                                    subStep,
                                });
                            }}
                        >
                            <Icon type="edit"/>
                        </Button>
                    );

                    if ((index + 1 ) < this.state.step.sSteps.length) {
                        buttons.push(
                            <Button
                                onClick={() => {
                                    this.props.onMoveSubStepBackward(
                                        this.state.step,
                                        subStep
                                    );
                                }}
                                disabled={!!this.props.scheme.activatedAt}
                            >
                                <Icon type="down"/>
                            </Button>
                        );
                    }

                    if (index > 0) {
                        buttons.push(
                            <Button
                                onClick={() => {
                                    this.props.onMoveSubStepForward(
                                        this.state.step,
                                        subStep
                                    );
                                }}
                                disabled={!!this.props.scheme.activatedAt}
                            >
                                <Icon type="up"/>
                            </Button>
                        );
                    }

                    return (
                        <span>
                            {buttons.map((button, index) => {
                                return (
                                    <span key={index}>
                                        <span style={{float: 'right'}}>{button}</span>
                                        <span style={{float: 'right'}}>&nbsp;</span>
                                    </span>
                                );
                            })}
                        </span>
                    );
                }
            }
        ];

        return this.state.step && (
            <Table rowKey="id"
                   dataSource={this.state.step.sSteps}
                   columns={entityColumns}
                   pagination={false}
            />
        );
    }

    public render() {
        return (
            <div>
                {this.renderStepForm()}
                {this.renderSubStepForm()}
                <Row gutter={32}>
                    <Col span={12}>
                        {this.renderStepTableHeader()}
                        {this.renderStepTable()}
                    </Col>
                    <Col span={12}>
                        {this.renderSubStepTableHeader()}
                        {this.renderSubStepTable()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Index;