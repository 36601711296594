import * as React from 'react';
import { connect } from 'react-redux';
import { get, assign } from 'lodash';
import { RouteComponentProps, withRouter } from 'react-router';
import { FormComponentProps } from 'antd/lib/form';
import { Button, Form as AntForm, Input, Select, notification } from 'antd';
import editUser from '../../creator/user/edit';
import createUser from '../../creator/user/create';
import IUser from '../../model/user/IUser';
import setUser from '../../creator/user/setUser';

export interface IProps extends RouteComponentProps<{ id: string }>, FormComponentProps {
    user: IUser;
    token?: string;
    save?: (token: string, user: IUser) => void;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Form extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue, getFieldValue} = this.props.form;

        const firstNameError = getFieldError('firstName');
        const lastNameError = getFieldError('lastName');
        const userNameError = getFieldError('userName');
        const passwordError = getFieldError('password');
        const passwordRepeatError = getFieldError('passwordRepeat');
        const emailError = getFieldError('email');
        const rolesError = getFieldError('roles');

        return (
            <AntForm onSubmit={this.onSubmit.bind(this)}>
                <h1>
                    {this.props.user.userName || 'Neuer Benutzer'}
                    <AntForm.Item style={{float: 'right'}}>
                        <Button
                            size="large"
                            type="danger"
                            htmlType="submit"
                            disabled={hasErrors(getFieldsError())}
                        >
                            auf Server übertragen
                        </Button>
                    </AntForm.Item>
                </h1>
                <AntForm.Item
                    validateStatus={userNameError ? 'error' : 'success'}
                    help={userNameError || ''}
                    label="Nutzername"
                >
                    {getFieldDecorator('userName', {
                        initialValue: this.props.user.userName,
                        rules: [{required: true, message: 'Bitte Nutzernamen eingeben.'}],

                    })(
                        <Input
                            disabled={!!this.props.user.id}
                            onChange={(e) => {
                                setFieldsValue({
                                    userName: e.target.value
                                })
                            }}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={firstNameError ? 'error' : 'success'}
                    help={firstNameError || ''}
                    label="Vorname"
                >
                    {getFieldDecorator('firstName', {
                        initialValue: this.props.user.firstName,
                        rules: [{required: true, message: 'Bitte Vorname eingeben.'}],

                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    firstName: e.target.value
                                })
                            }}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={lastNameError ? 'error' : 'success'}
                    help={lastNameError || ''}
                    label="Nachname"
                >
                    {getFieldDecorator('lastName', {
                        initialValue: this.props.user.lastName,
                        rules: [{required: true, message: 'Bitte Nachname eingeben.'}],

                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    lastName: e.target.value
                                })
                            }}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={passwordError ? 'error' : 'success'}
                    help={passwordError || ''}
                    label={this.props.user.id ? "Neues Passwort" : "Passwort"}
                >
                    {getFieldDecorator('password', {
                        initialValue: null,

                    })(
                        <Input
                            type="password"
                            onChange={(e) => {
                                setFieldsValue({
                                    password: e.target.value
                                })
                            }}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={passwordRepeatError ? 'error' : 'success'}
                    help={passwordRepeatError || ''}
                    label={this.props.user.id ? "Neues Passwort wiederholen" : "Passwort wiederholen"}
                >
                    {getFieldDecorator('passwordRepeat', {
                        initialValue: null,
                        rules: [
                            {
                                validator: (rule, passwordRepeat, cb) => {
                                    const password = getFieldValue('password');

                                    if (!password && !passwordRepeat) {
                                        cb();
                                    } else if (passwordRepeat !== password) {
                                        cb('Passwörter sind nicht identisch.')
                                    } else {
                                        cb();
                                    }
                                }
                            },
                        ],
                    })(
                        <Input
                            type="password"
                            onChange={(e) => {
                                setFieldsValue({
                                    passwordRepeat: e.target.value
                                })
                            }}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={emailError ? 'error' : 'success'}
                    help={emailError || ''}
                    label="E-Mail"
                >
                    {getFieldDecorator('email', {
                        initialValue: this.props.user.email,
                        rules: [{required: true, message: 'Bitte E-Mail eingeben.'}],

                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    email: e.target.value
                                })
                            }}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={rolesError ? 'error' : 'success'}
                    help={rolesError || ''}
                    label="Rollen"
                >
                    {getFieldDecorator('roles', {
                        initialValue: this.props.user.roles,
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{required: true, message: 'Bitte Rollen auswählen.'}],
                    })(
                        <Select mode={'multiple'} onChange={(value: string) => {
                            setFieldsValue({
                                roles: value
                            })
                        }}>
                            <Select.Option value="ROLE_USER">Benutzer</Select.Option>
                            <Select.Option value="ROLE_ADMIN">Administrator</Select.Option>
                        </Select>
                    )}
                </AntForm.Item>
            </AntForm>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.save(
                    this.props.token,
                    assign({}, {
                        id: this.props.user.id,
                        userName: values.userName,
                        firstName: values.firstName,
                        lastName: values.lastName,
                        password: values.password,
                        email: values.email,
                        roles: values.roles
                    })
                );
            }
        });
    }
}

export default connect(
    (state, ownProps: IProps) => ({
        token: get(state, 'auth.token', null),
    } as Partial<IProps>),
    (dispatch, ownProps: IProps) => ({
        save: (token: string, user: IUser) => {
            dispatch(user.id ? editUser(token, user) : createUser(token, user)).then(() => {
                dispatch(setUser(null));

                notification['success']({
                    message: 'Erfolgreich',
                    description: (user.id ? 'Der Benutzer wurde geändert.' : 'Der Benutzer wurde angelegt')
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Der Benutzer konnte nicht geändert werden.'
                });
            });
        },
    } as Partial<IProps>)
)(
    withRouter(
        AntForm.create()(Form)
    )
);