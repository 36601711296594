import * as React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Spin } from 'antd';

export interface IProps {
    busy?: boolean;
}

class Blocker extends React.Component<IProps, {}> {
    public render() {
        return this.props.busy ? <div style={{
            position: 'fixed',
            width: '100%',
            height: '100%',
            zIndex: 9999,
            opacity: 0.5,
            backgroundColor: '#fff',
        }}>
            <div style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)',
                WebkitTransform: 'translate(-50%, -50%)'
            }}>
                <Spin size="large"/>
            </div>
        </div> : null;
    }
}

export default connect(
    (state) => ({
        busy: get(state, 'busy', false),
    } as Partial<IProps>),
)(
    Blocker
);