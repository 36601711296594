import { ThunkAction } from 'redux-thunk';
import { types } from '../../action/constants';
import IScheme from '../../model/scheme/IScheme';

export default function setScheme(scheme: IScheme): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            dispatch({
                type: types.set_scheme,
                scheme
            });

            resolve();
        });
    }
}