import { assign, get } from 'lodash';
import { v1 } from 'uuid';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IEntityType from '../../../model/scheme/IEntityType';
import IScheme from '../../../model/scheme/IScheme';

export default function addEntityType(entityType: IEntityType): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            let buffer: IScheme = assign({}, scheme);

            buffer.entityTypes.push(
                assign({}, entityType, {
                    id: `frontend-${v1()}`,
                })
            );

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}