import * as React from 'react';
import { get, noop } from 'lodash';
import { connect } from 'react-redux';
import { notification } from 'antd';
import { RouteComponentProps } from 'react-router';
import getScheme from '../../creator/scheme/getScheme';

export interface IProps extends RouteComponentProps<{ id: string }> {
    token: string;
    id: string;
    getScheme: (token: string, id: string) => void;
}

export default function edit<T>(WrappedComponent: React.ComponentType<T>): React.ComponentType<T> {
    class EditWrapper extends React.Component<T & IProps, {}> {
        constructor(props: T & IProps, context: {}) {
            super(props, context);
        }

        public componentWillMount(): void {
            this.props.getScheme(
                this.props.token,
                this.props.id
            );
        }

        public render() {
            return <WrappedComponent {...this.props}/>
        }
    }

    return connect(
        (state, ownProps: IProps) => ({
            token: get(state, 'auth.token', null),
            id: ownProps.match.params.id
        } as Partial<IProps>),
        (dispatch) => ({
            getScheme: (token: string, id: string) => {
                dispatch(getScheme(token, id)).then(noop).catch(() => {
                    notification['error']({
                        message: 'Fehler',
                        description: 'Das Schema konnte nicht geladen werden.'
                    });
                });
            },
        })
    )(EditWrapper) as any;
}
