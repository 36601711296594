import * as React from 'react';
import { assign } from 'lodash';
import { Input, Button, Form as AntForm, Select } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IScheme from '../../../../model/scheme/IScheme';
import IGeneral from '../../../../model/scheme/IGeneral';

export interface IProps extends FormComponentProps {
    scheme: IScheme;
    onChange: (general: IGeneral) => void;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Form extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;

        const nameError = getFieldError('name');
        const appError = getFieldError('app');

        return (
            <AntForm onSubmit={this.onSubmit.bind(this)}>
                <h2>allgemein</h2>
                <AntForm.Item
                    validateStatus={nameError ? 'error' : 'success'}
                    help={nameError || ''}
                    label="Name"
                >
                    {getFieldDecorator('name', {
                        initialValue: this.props.scheme.name,
                        rules: [{required: true, message: 'Bitte Name eingeben.'}],

                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    name: e.target.value
                                })
                            }}
                            disabled={!!this.props.scheme.activatedAt}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={appError ? 'error' : 'success'}
                    help={appError || ''}
                    label="App"
                >
                    {getFieldDecorator('app', {
                        initialValue: this.props.scheme.app,
                        rules: [{required: true, message: 'Bitte App auswählen.'}],

                    })(
                        <Select onChange={(value: string) => {
                            setFieldsValue({
                                app: value
                            })
                        }} disabled={!!this.props.scheme.activatedAt}>
                            <Select.Option value="sales">Vertrieb</Select.Option>
                        </Select>
                    )}
                </AntForm.Item>
                <AntForm.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={!!this.props.scheme.activatedAt || hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </AntForm.Item>
            </AntForm>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onChange(
                    assign({}, {
                        id: this.props.scheme.id,
                        activatedAt: this.props.scheme.activatedAt,
                        deactivatedAt: this.props.scheme.deactivatedAt,
                        name: values.name,
                        app: values.app
                    })
                );
            }
        });
    }
}

export default AntForm.create()(Form);