import * as React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import IUser from '../../model/user/IUser';
import setUser from '../../creator/user/setUser';

export interface IProps {
    user?: IUser;
    setUser: (user: IUser) => void;
}

export interface IState {
    redirect: boolean;
}

export default function create<T>(WrappedComponent: React.ComponentType<T>): React.ComponentType<T> {
    class CreateWrapper extends React.Component<T & IProps, IState> {
        constructor(props: T & IProps, context: {}) {
            super(props, context);

            this.state = {
                redirect: false
            };
        }

        public componentWillReceiveProps(nextProps: Readonly<T & IProps>): void {
            if (this.props.user && !nextProps.user) {
                this.setState({
                    redirect: true
                });
            }
        }

        public componentWillMount(): void {
            this.props.setUser({
                id: null,
                userName: null,
                firstName: null,
                lastName: null,
                password: null,
                email: null,
                roles: [],
            });
        }

        public componentWillUnmount(): void {
            this.props.setUser(null);
        }

        public render() {
            if (this.state.redirect) {
                return (
                    <Redirect to="/user"/>
                );
            } else if (!this.props.user) {
                return null;
            } else {
                return <WrappedComponent {...this.props}/>
            }
        }
    }

    return connect(
        (state, ownProps: IProps) => ({
            user: get(state, 'user', null)
        } as Partial<IProps>),
        (dispatch) => ({
            setUser: (user: IUser) => {
                dispatch(setUser(user));
            }
        })
    )(CreateWrapper) as any;
}
