import * as React from 'react';
import { assign, get, omit, omitBy } from 'lodash';
import { Input, Button, Form, Select, InputNumber } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IEntity from '../../../../model/scheme/IEntity';
import IEntityField from '../../../../model/scheme/IEntityField';
import Upload from '../../../index/Upload';
import { getApiHost, getApiPort, getApiProtocol } from '../../../../util/env';

export interface IProps extends FormComponentProps {
    token: string;
    entity: IEntity;
    entityFields: IEntityField[];
    onChange: (entity: IEntity) => void;
    disabled: boolean;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class EntityForm extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;
        const nameError = getFieldError('n');

        return (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Item
                    validateStatus={nameError ? 'error' : 'success'}
                    help={nameError || ''}
                    label={'Name'}
                >
                    {getFieldDecorator('n', {
                        initialValue: this.props.entity.n,
                        rules: [{required: true, message: 'Bitte Name eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    n: e.target.value
                                })
                            }}
                            disabled={this.props.disabled}
                        />
                    )}
                </Form.Item>
                {this.props.entityFields.map((entityField) => {
                    switch (entityField.t) {
                        case 'text':
                            return this.renderTextField(entityField);
                        case 'number':
                            return this.renderNumberField(entityField);
                        case 'imageUpload':
                            return this.renderUploadField(entityField);
                    }
                })}
                <Form.Item label={'Tags'}>
                    {getFieldDecorator('t', {
                        initialValue: this.props.entity.t
                    })(
                        <Select
                            mode="tags"
                            onChange={(value) => {
                                setFieldsValue({
                                    t: value
                                })
                            }}
                            disabled={this.props.disabled}
                        >
                            <Select.Option key="Optionen">Optionen</Select.Option>
                            <Select.Option key="Innenausbau">Innenausbau</Select.Option>
                            <Select.Option key="Baunebenkosten">Baunebenkosten</Select.Option>
                            <Select.Option key="Eigenleistung">Eigenleistung</Select.Option>
                            <Select.Option key="Erdarbeiten">Erdarbeiten und Erschließung</Select.Option>
                            <Select.Option key="Keller">Keller</Select.Option>
                            <Select.Option key="Kellerkalk">Kellerkalkulation</Select.Option>
                            <Select.Option key="Bodenplatte">Bodenplatte</Select.Option>
                            <Select.Option key="KfW55">KfW55</Select.Option>
                            <Select.Option key="KfW40">KfW40</Select.Option>
                            <Select.Option key="KfW40plus">KfW40plus</Select.Option>
                            <Select.Option key="Innentreppe">Innentreppe</Select.Option>
                            <Select.Option key="Tondachziegel">Tondachziegel</Select.Option>
                            <Select.Option key="Erdgeschoss">Erdgeschoss</Select.Option>
                            <Select.Option key="Obergeschoss">Obergeschoss</Select.Option>
                            <Select.Option key="Deckblatt">Deckblatt</Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.props.disabled || hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </Form.Item>
            </Form>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onChange(
                    assign({}, this.props.entity, {
                        n: values.n,
                        t: values.t,
                        d: omitBy(
                            omit(values, ['n', 't']),
                            (value) => {
                                return value === null;
                            }
                        )
                    })
                );
            }
        });
    }

    protected renderTextField(entityField: IEntityField) {
        const {getFieldDecorator, getFieldError, setFieldsValue} = this.props.form;
        const fieldError = getFieldError(entityField.n);

        return (
            <Form.Item
                key={entityField.n}
                validateStatus={fieldError ? 'error' : 'success'}
                help={fieldError || ''}
                label={entityField.n}
            >
                {getFieldDecorator(entityField.n, {
                    initialValue: get(this.props.entity.d, entityField.n, null),
                })(
                    <Input
                        onChange={(e) => {
                            setFieldsValue({
                                [entityField.n]: e.target.value
                            })
                        }}
                        disabled={this.props.disabled}
                    />
                )}
            </Form.Item>
        );
    }

    protected renderNumberField(entityField: IEntityField) {
        const {getFieldDecorator, getFieldError, setFieldsValue} = this.props.form;
        const fieldError = getFieldError(entityField.n);

        return (
            <Form.Item
                key={entityField.n}
                validateStatus={fieldError ? 'error' : 'success'}
                help={fieldError || ''}
                label={entityField.n}
            >
                {getFieldDecorator(entityField.n, {
                    initialValue: get(this.props.entity.d, entityField.n, null),
                })(
                    <InputNumber
                        onChange={(value) => {
                            setFieldsValue({
                                [entityField.n]: value
                            })
                        }}
                        disabled={this.props.disabled}
                    />
                )}
            </Form.Item>
        );
    }

    protected renderUploadField(entityField: IEntityField) {
        const {getFieldDecorator, getFieldError, setFieldsValue, getFieldValue} = this.props.form;
        const fieldError = getFieldError(entityField.n);

        return (
            <Form.Item
                key={entityField.n}
                validateStatus={fieldError ? 'error' : 'success'}
                help={fieldError || ''}
                label={entityField.n}
            >
                {getFieldDecorator(entityField.n, {
                    initialValue: get(this.props.entity.d, entityField.n, null),
                })(
                    <Upload
                        file={getFieldValue(entityField.n)}
                        onChange={(file) => {
                            setFieldsValue({
                                [entityField.n]: file
                            })
                        }}
                        action={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/upload/create`}
                        headers={{
                            'X-AUTH-TOKEN': this.props.token
                        }}
                        name="file"
                        showUploadList={false}
                        accept="image/*"
                        disabled={this.props.disabled}
                    />
                )}
            </Form.Item>
        );
    }
}

export default Form.create()(EntityForm);
