import { assign, get } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IGeneral from '../../../model/scheme/IGeneral';

export default function set(general: IGeneral): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {

            const scheme = get(getState(), 'scheme');

            dispatch({
                type: types.set_scheme,
                scheme: assign({}, scheme, general)
            });

            resolve();
        });
    }
}