import { assign, get, findIndex } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IScheme from '../../../model/scheme/IScheme';
import IEntity from '../../../model/scheme/IEntity';
import IEntityType from '../../../model/scheme/IEntityType';

export default function updateEntity(entityType: IEntityType, entity: IEntity): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            const entityTypeIndex = findIndex(scheme.entityTypes, {
                id: entityType.id
            });

            const entityIndex = findIndex(scheme.entityTypes[entityTypeIndex].e, {
                id: entity.id
            });

            let buffer: IScheme = assign({}, scheme);

            buffer.entityTypes[entityTypeIndex].e[entityIndex] = entity;

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}