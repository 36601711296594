import { assign, get, filter } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IEntityType from '../../../model/scheme/IEntityType';
import IScheme from '../../../model/scheme/IScheme';
import cleanupEntityReferences from '../cleanupEntityReferences';

export default function deleteEntityType(entityType: IEntityType): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            let buffer: IScheme = assign({}, scheme);

            buffer.entityTypes = filter(buffer.entityTypes, (item) => {
                return item.id !== entityType.id;
            });

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            dispatch(cleanupEntityReferences());

            resolve();
        });
    }
}