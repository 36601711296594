import { ThunkAction } from 'redux-thunk';
import { types } from '../../action/constants';
import getSchemeList from './getSchemeList';
import timeout from '../index/timeout';
import { getApiHost, getApiPort, getApiProtocol } from '../../util/env';

export default function deactivateScheme(token: string, id: number): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve, reject) => {
            dispatch({
                type: types.set_busy,
                busy: true
            });

            fetch(`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/scheme/deactivate`, {
                body: JSON.stringify({id}),
                cache: 'no-cache',
                headers: {
                    'content-type': 'application/json',
                    'X-AUTH-TOKEN': token
                },
                method: 'POST',
                mode: 'cors',
                redirect: 'follow'
            }).then((response) => {
                if (!response.ok) {
                    throw response;
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                dispatch(getSchemeList(token)).then(resolve, reject);
            }).catch((response: Response) => {
                if (response.status === 401) {
                    dispatch(timeout());
                }

                dispatch({
                    type: types.set_busy,
                    busy: false
                });

                reject();
            });
        });
    }
}
