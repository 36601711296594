import * as React from 'react';
import { get } from 'lodash';
import { NavLink } from 'react-router-dom';
import { Layout, Menu, Icon, Modal, notification } from 'antd';
import { connect } from 'react-redux';
import Login from '../../component/index/Login';
import logout from '../../creator/index/logout';
import login from '../../creator/index/login';

const {Header, Content, Footer} = Layout;

export interface IProps {
    anonymous?: boolean;
    isTimedOut?: boolean;
    userName?: string;
    logout?: () => void;
    login?: (userName: string, password: string) => void;
}

export default function layout<T>(WrappedComponent: React.ComponentType<T>): React.ComponentType<T> {
    class LayoutComponent extends React.Component<T & IProps, {}> {
        public render() {
            return (
                <Layout>
                    <Header>
                        <div className="logo" style={{float: 'left', color: '#fff', paddingRight: '20px'}}>
                            <strong>Hauskonfigurator</strong>
                        </div>
                        {!this.props.anonymous && (
                            <Menu
                                mode="horizontal"
                                style={{lineHeight: '64px'}}
                                theme="dark"
                                onClick={(data) => {
                                    if (data.key === 'logout') {
                                        this.props.logout();
                                    }
                                }}
                            >
                                <Menu.Item key="scheme">
                                    <NavLink to="/scheme"><Icon type="setting"/>Schemen</NavLink>
                                </Menu.Item>
                                <Menu.Item key="configuration">
                                    <NavLink to="/configuration"><Icon type="database"/>Konfigurationen</NavLink>
                                </Menu.Item>
                                <Menu.Item key="visual">
                                    <NavLink to="/visual"><Icon type="eye-o"/>Visualisierungen</NavLink>
                                </Menu.Item>
                                <Menu.Item key="user">
                                    <NavLink to="/user"><Icon type="team"/>Benutzer</NavLink>
                                </Menu.Item>
                                <Menu.Item key="logout">
                                    <Icon type="logout"/>{`${this.props.userName} abmelden`}
                                </Menu.Item>
                            </Menu>
                        )}
                    </Header>
                    <Content style={{padding: '0 50px'}}>
                        <Layout style={{padding: '24px 0', background: '#fff'}}>
                            <Content style={{padding: '0 24px', minHeight: 280}}>
                                {this.props.anonymous ? <Login
                                    onLogin={this.props.login}
                                    anonymous={this.props.anonymous}
                                    isTimedOut={this.props.isTimedOut}
                                /> : <WrappedComponent {...this.props} />}
                                {this.props.isTimedOut ? <Modal
                                    closable={false}
                                    visible={true}
                                    destroyOnClose={true}
                                    footer={null}>
                                    <Login
                                        onLogin={this.props.login}
                                        anonymous={this.props.anonymous}
                                        isTimedOut={this.props.isTimedOut}
                                    />
                                </Modal> : null}
                            </Content>
                        </Layout>
                    </Content>
                </Layout>
            );
        }
    }

    return connect(
        (state: any): Partial<IProps> => {
            return {
                anonymous: get(state, 'auth.anonymous', true),
                isTimedOut: get(state, 'auth.isTimedOut', false),
                userName: get(state, 'auth.userName', true),
            } as Partial<IProps>
        },
        (dispatch) => ({
            login: (userName: string, password: string) => {
                dispatch(login(userName, password)).catch(() => {
                    notification['error']({
                        message: 'Fehler',
                        description: 'Der Benutzer konnte nicht angemeldet werden.'
                    });
                });
            },
            logout: () => {
                dispatch(logout());
            }
        } as Partial<IProps>)
    )(LayoutComponent) as any;
}
