import { get, assign } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import ISetAuth from '../../action/index/ISetAuth';
import { types } from '../../action/constants';
import IAuth from '../../model/index/IAuth';

export default function timeout(): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise<void>((resolve) => {
            const auth: IAuth = get(getState(), 'auth');
            const setAuth: ISetAuth = {
                type: types.set_auth,
                auth: assign({}, auth, {
                    isTimedOut: true
                })
            };

            dispatch(setAuth);

            resolve();
        });
    }
}