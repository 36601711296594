import * as React from 'react';
import { omit, assign, get } from 'lodash';
import { Button, Form as AntForm, Upload, Icon, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';

export interface IProps extends FormComponentProps {
    file: string;
    name: string;
    action: string;
    headers?: {
        [index: string]: string;
    },
    showUploadList?: boolean;
    accept?: string;
    onChange: (file: string) => void;
    disabled: boolean;
}

class Form extends React.Component<IProps, {}> {
    public render() {
        const props = assign({}, omit(this.props, ['file', 'onChange']));

        return (
            <Upload.Dragger
                {...props}
                onChange={(info) => {
                    if (info.file.status === 'done') {
                        this.props.onChange(info.file.response.file);

                        notification['success']({
                            message: 'Erfolgreich',
                            description: 'Die Datei wurde erfolgreich hochgeladen.'
                        });
                    } else if (info.file.status === 'error') {
                        notification['error']({
                            message: 'Fehler',
                            description: 'Die Datei konnte nicht hochgeladen werden.'
                        });
                    }
                }}
                disabled={this.props.disabled}
            >
                <Button size="small">
                    <Icon type="upload"/> Upload {this.props.file}
                </Button>
            </Upload.Dragger>
        );
    }
}

export default AntForm.create()(Form);