import * as React from 'react';
import { assign } from 'lodash';
import { HashRouter as Router, Route, Redirect } from 'react-router-dom';
import { applyMiddleware, createStore, Store as ReduxStore } from 'redux';
import { Provider as ReduxProvider } from 'react-redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import indexReduce from '../../reducer/index/reduce';
import schemeReduce from '../../reducer/scheme/reduce';
import userReduce from '../../reducer/user/reduce';
import visualReduce from '../../reducer/visual/reduce';
import configurationReduce from '../../reducer/configuration/reduce';
import layout from '../../hoc/index/layout';
import editScheme from '../../hoc/scheme/edit';
import createScheme from '../../hoc/scheme/create';
import IState from '../../state/IState';
import { actions } from '../../action/constants';
import SchemeList from '../scheme/List';
import VisualList from '../visual/List';
import SchemeForm from '../scheme/Form';
import Blocker from './Blocker';
import UserList from '../user/List';
import editUser from '../../hoc/user/edit';
import createUser from '../../hoc/user/create';
import UserForm from '../user/Form';
import ConfigurationList from '../configuration/List';

export default class Index extends React.Component<{}, {}> {
    protected reduxStore: ReduxStore<{}>;

    constructor(props: {}, context: {}) {
        super(props, context);

        let middlewares: any = [thunk];

        if (process.env.NODE_ENV === 'development') {
            middlewares.push(createLogger({collapsed: true, diff: false}));
        }

        this.reduxStore = createStore<any>(
            (state: IState, action: actions): IState => {
                const reducers = [indexReduce, schemeReduce, userReduce, configurationReduce, visualReduce];

                for (let i = 0; i < reducers.length; i++) {
                    state = assign({}, state, reducers[i](state, action));
                }

                return state;
            },
            {},
            applyMiddleware(...middlewares)
        );
    }

    public render() {
        const Routes = () => (
            <div>
                <Route exact path="/" component={
                    () => <Redirect to="/scheme"/>
                }/>
                <Route path="/scheme" exact={true} component={layout(SchemeList)}/>
                <Route path="/scheme/form/:id" component={layout(editScheme(SchemeForm))}/>
                <Route path="/scheme/form" exact={true} component={layout(createScheme(SchemeForm))}/>
                <Route path="/configuration" component={layout(ConfigurationList)}/>
                <Route path="/user" exact={true} component={layout(UserList)}/>
                <Route path="/user/form/:id" component={layout(editUser(UserForm))}/>
                <Route path="/user/form" exact={true} component={layout(createUser(UserForm))}/>
                <Route path="/visual" exact={true} component={layout(VisualList)}/>
            </div>
        );

        return (
            <ReduxProvider store={this.reduxStore}>
                <div>
                    <Blocker/>
                    <Router>
                        <Routes/>
                    </Router>
                </div>
            </ReduxProvider>
        );
    }
}