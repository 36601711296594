import * as React from 'react';
import { assign } from 'lodash';
import { Button, Form as AntForm, Input } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IWorkflowStep from '../../../../model/scheme/IWorkflowStep';

export interface IProps extends FormComponentProps {
    step: IWorkflowStep;
    onChange: (step: IWorkflowStep) => void;
    disabled: boolean;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class StepForm extends React.Component<IProps, {}> {
    public render() {
        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;
        const nameError = getFieldError('name');
        const descriptionError = getFieldError('desc');

        return (
            <AntForm onSubmit={this.onSubmit.bind(this)}>
                <AntForm.Item
                    validateStatus={nameError ? 'error' : 'success'}
                    help={nameError || ''}
                    label={'Name'}
                >
                    {getFieldDecorator('name', {
                        initialValue: this.props.step.name,
                        rules: [{required: true, message: 'Bitte Name eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    name: e.target.value
                                })
                            }}
                            disabled={this.props.disabled}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={descriptionError ? 'error' : 'success'}
                    help={descriptionError || ''}
                    label={'Beschreibung / Frage'}
                >
                    {getFieldDecorator('desc', {
                        initialValue: this.props.step.desc,
                        rules: [{required: true, message: 'Bitte Beschreibung / Frage eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    desc: e.target.value
                                })
                            }}
                            disabled={this.props.disabled}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={this.props.disabled || hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </AntForm.Item>
            </AntForm>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onChange(
                    assign({}, this.props.step, {
                        name: values.name,
                        desc: values.desc,
                    })
                );
            }
        });
    }
}

export default AntForm.create()(StepForm);