import { assign, get } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import ILayout from '../../../model/scheme/ILayout';

export default function set(layout: ILayout): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {

            const scheme = get(getState(), 'scheme');

            dispatch({
                type: types.set_scheme,
                scheme: assign({}, scheme, layout)
            });

            resolve();
        });
    }
}