import * as React from 'react';
import { v1 } from 'uuid';
import { assign } from 'lodash';
import { Input, Button, Form as AntForm } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import ILayout from '../../../../model/scheme/ILayout';
import IScheme from '../../../../model/scheme/IScheme';
import Upload from '../../../index/Upload';
import { getApiHost, getApiPort, getApiProtocol } from '../../../../util/env';

export interface IProps extends FormComponentProps {
    token: string;
    scheme: IScheme;
    onChange: (layout: ILayout) => void;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Form extends React.Component<IProps, {}> {
    public render() {
        const {setFieldsValue, getFieldValue, getFieldDecorator, getFieldsError} = this.props.form;

        return (
            <AntForm onSubmit={this.onSubmit.bind(this)}>
                <AntForm.Item
                    label={'Header-Bild'}
                >
                    {getFieldDecorator('headerFile', {
                        initialValue: this.props.scheme.headerFile ? this.props.scheme.headerFile.f : null,
                    })(
                        <Upload
                            file={getFieldValue('headerFile')}
                            onChange={(file) => {
                                setFieldsValue({
                                    headerFile: file
                                })
                            }}
                            action={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/upload/create`}
                            headers={{
                                'X-AUTH-TOKEN': this.props.token
                            }}
                            name="file"
                            showUploadList={false}
                            accept="image/*"
                            disabled={false}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    label={'Footer-Bild'}
                >
                    {getFieldDecorator('footerFile', {
                        initialValue: this.props.scheme.footerFile ? this.props.scheme.footerFile.f : null,
                    })(
                        <Upload
                            file={getFieldValue('footerFile')}
                            onChange={(file) => {
                                setFieldsValue({
                                    footerFile: file
                                })
                            }}
                            action={`${getApiProtocol()}://${getApiHost()}:${getApiPort()}/upload/create`}
                            headers={{
                                'X-AUTH-TOKEN': this.props.token
                            }}
                            name="file"
                            showUploadList={false}
                            accept="image/*"
                            disabled={false}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    label="Revision"
                >
                    {getFieldDecorator('revision', {
                        initialValue: this.props.scheme.revision

                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    revision: e.target.value
                                })
                            }}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </AntForm.Item>
            </AntForm>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onChange(
                    assign({}, {
                        headerFile: values.headerFile ? {
                            id: `frontend-${v1()}`,
                            f: values.headerFile,
                            g: null
                        } : null,
                        footerFile: values.footerFile ? {
                            id: `frontend-${v1()}`,
                            f: values.footerFile,
                            g: null
                        } : null,
                        revision: values.revision ? values.revision : null
                    })
                );
            }
        });
    }
}

export default AntForm.create()(Form);