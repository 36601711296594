import { assign, get, filter } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IScheme from '../../../model/scheme/IScheme';
import IWorkflowStep from '../../../model/scheme/IWorkflowStep';

export default function deleteStep(workflowStep: IWorkflowStep): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            let buffer: IScheme = assign({}, scheme);

            buffer.workflowSteps = filter(buffer.workflowSteps, (item) => {
                return item.id !== workflowStep.id;
            });

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}