import * as React from 'react';
import { find, noop, forEach, join } from 'lodash';
import { Button, Table, Icon, Modal } from 'antd';
import IScheme from '../../../../model/scheme/IScheme';
import IReference from '../../../../model/scheme/IReference';
import IEntity from '../../../../model/scheme/IEntity';
import IEntityType from '../../../../model/scheme/IEntityType';
import Form from './Form';

export interface IProps {
    scheme: IScheme;
    onAdd: (reference: IReference) => void;
    onDelete: (reference: IReference) => void;
}

export interface IState {
    referenceFormVisible: boolean;
}

class Index extends React.Component<IProps, IState> {
    constructor(props: IProps, context: {}) {
        super(props, context);

        this.state = {
            referenceFormVisible: false,
        };
    }

    public renderReferenceForm() {
        return this.state.referenceFormVisible && (
            <Modal
                title={'Verknüpfung erstellen'}
                visible={true}
                onOk={noop}
                onCancel={() => {
                    this.setState({
                        referenceFormVisible: false
                    });
                }}
                closable={true}
                destroyOnClose={true}
                footer={null}
            >
                <Form
                    entityTypes={this.props.scheme.entityTypes}
                    onChange={(reference) => {
                        this.props.onAdd(reference);

                        this.setState({
                            referenceFormVisible: false
                        });
                    }}
                    disabled={!!this.props.scheme.activatedAt}
                />
            </Modal>
        );
    }

    public renderReferenceTableHeader() {
        return (
            <h2>
                Verknüpfungen
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            referenceFormVisible: true
                        });
                    }}
                    style={{float: 'right'}}
                    disabled={!!this.props.scheme.activatedAt}
                >
                    <Icon type="plus"/>
                </Button>
            </h2>
        );
    }

    public renderReferenceTable() {
        const referenceColumns = [
            {
                title: 'Entitäten-Typ',
                key: 'rETA',
                render: (text: string, record: IReference) => {
                    try {
                        let entities: string[] = [];
                        forEach(record.rETA, (entity) => {
                            entities.push(this.getEntityTypeById(entity).n);
                        });
                        return join(entities, ', ');
                    } catch (e) {
                        return null;
                    }

                },
                width: '15%'
            },
            {
                title: 'Entität',
                key: 'rEA',
                render: (text: string, record: IReference) => {
                    try {
                        let entities: string[] = [];
                        forEach(record.rEA, (entity) => {
                            entities.push(this.getEntityById(entity).n);
                        });
                        return join(entities, ', ');
                    } catch (e) {
                        return null;
                    }
                },
                width: '20%'
            },
            {
                title: 'Bedingung',
                key: 'constraint',
                render: (text: string, record: IReference) => {
                    return (record.constraint === 'need' ? 'erfordern' : 'werden ausgeschlossen durch' )
                },
                width: '10%'
            },
            {
                title: 'Entitäten-Typ',
                key: 'rETB',
                render: (text: string, record: IReference) => {
                    try {
                        let entities: string[] = [];
                        forEach(record.rETB, (entity) => {
                            entities.push(this.getEntityTypeById(entity).n);
                        });
                        return join(entities, ', ');
                    } catch (e) {
                        return null;
                    }
                },
                width: '15%'
            },
            {
                title: 'Entität',
                key: 'rEB',
                render: (text: string, record: IReference) => {
                    try {
                        let entities: string[] = [];
                        forEach(record.rEB, (entity) => {
                            entities.push(this.getEntityById(entity).n);
                        });
                        return join(entities, ', ');
                    } catch (e) {
                        return null;
                    }
                },
                width: '20%'
            },
            {
                title: 'Operator',
                key: 'operator',
                render: (text: string, record: IReference) => {
                    return (record.operator === 'and' ? 'UND' : 'ODER' )
                },
                width: '10%'
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, reference: IReference) => {
                    return (
                        <span>
                            <Button
                                type="danger"
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Möchten Sie diese Verknüpfung wirklich löschen?`,
                                        okText: 'ja',
                                        okType: 'danger',
                                        cancelText: 'nein',
                                        onOk: () => {
                                            this.props.onDelete(reference);
                                        }
                                    });
                                }}
                                style={{float: 'right'}}
                                disabled={!!this.props.scheme.activatedAt}
                            >
                                <Icon type="delete"/>
                            </Button>
                        </span>
                    );
                },
                width: '5%'
            }
        ];

        return (
            <Table rowKey="id" dataSource={this.props.scheme.references.slice().reverse()} columns={referenceColumns}
                   pagination={false}
            />
        );
    }

    public render() {
        return (
            <div>
                {this.renderReferenceForm()}
                {this.renderReferenceTableHeader()}
                {this.renderReferenceTable()}
            </div>
        );
    }

    protected getEntityTypeById(id: string): IEntityType {
        let buffer = find(this.props.scheme.entityTypes, {
            id
        });

        if (buffer) {
            return buffer;
        }

        throw new Error(`Cannot find entityType with id ${id}.`);
    }

    protected getEntityById(id: string): IEntity {
        const entityTypes = this.props.scheme.entityTypes;

        for (let i = 0; i < entityTypes.length; i++) {
            let buffer = find(entityTypes[i].e, {
                id
            });

            if (buffer) {
                return buffer;
            }
        }

        throw new Error(`Cannot find entity with id ${id}.`);
    }
}

export default Index;
