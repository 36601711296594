import * as React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import IScheme from '../../model/scheme/IScheme';
import setScheme from '../../creator/scheme/setScheme';

export interface IProps {
    scheme?: IScheme;
    setScheme: (scheme: IScheme) => void;
}

export interface IState {
    redirect: boolean;
}

export default function create<T>(WrappedComponent: React.ComponentType<T>): React.ComponentType<T> {
    class CreateWrapper extends React.Component<T & IProps, IState> {
        constructor(props: T & IProps, context: {}) {
            super(props, context);

            this.state = {
                redirect: false
            };
        }

        public componentWillReceiveProps(nextProps: Readonly<T & IProps>): void {
            if (this.props.scheme && !nextProps.scheme) {
                this.setState({
                    redirect: true
                });
            }
        }

        public componentWillMount(): void {
            this.props.setScheme({
                id: null,
                name: null,
                activatedAt: null,
                deactivatedAt: null,
                app: null,
                headerFile: null,
                footerFile: null,
                revision: null,
                entityTypes: [],
                visuals: [],
                references: [],
                priceLists: [],
                workflowSteps: [],
                pdfs: []
            });
        }

        public componentWillUnmount(): void {
            this.props.setScheme(null);
        }

        public render() {
            if (this.state.redirect) {
                return (
                    <Redirect to="/scheme"/>
                );
            } else if (!this.props.scheme) {
                return null;
            } else {
                return <WrappedComponent {...this.props}/>
            }
        }
    }

    return connect(
        (state, ownProps: IProps) => ({
            scheme: get(state, 'scheme', null)
        } as Partial<IProps>),
        (dispatch) => ({
            setScheme: (scheme: IScheme) => {
                dispatch(setScheme(scheme));
            }
        })
    )(CreateWrapper) as any;
}
