import * as React from 'react';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import { Table, Icon, Button, notification, Modal, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import getSchemeList from '../../creator/scheme/getSchemeList';
import deleteScheme from '../../creator/scheme/deleteScheme';
import activateScheme from '../../creator/scheme/activateScheme';
import deactivateScheme from '../../creator/scheme/deactivateScheme';
import cloneScheme from '../../creator/scheme/cloneScheme';
import IGeneral from "../../model/scheme/IGeneral";

export interface IProps {
    schemes: IGeneral[];
    token: string;
    getSchemeList: (token: string) => void;
    deleteScheme: (token: string, id: number) => void;
    activateScheme: (token: string, id: number) => void;
    deactivateScheme: (token: string, id: number) => void;
    cloneScheme: (token: string, id: number) => void;
}

class List extends React.Component<IProps, {}> {
    public componentWillMount(): void {
        this.props.getSchemeList(
            this.props.token
        );
    }

    public renderHeader() {
        return (
            <h1>
                Schemen
                    <Link to={'/scheme/form'}>
                        <Tooltip title="Neues Schema anlegen">
                            <Button
                                size="large"
                                type="primary"
                                style={{float: 'right'}}
                            >
                                <Icon type="plus"/> neues Schema
                            </Button>
                        </Tooltip>
                    </Link>
            </h1>
        );
    }

    public render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'name'
            },
            {
                title: 'App',
                key: 'app',
                render: (text: string, scheme: IGeneral) => {
                    return (
                        <span>{scheme.app === 'sales' ? 'Vertrieb' : null}</span>
                    );
                }
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, scheme: IGeneral) => {
                    let buttons: React.ReactElement<any>[] = [];

                    if (!scheme.activatedAt) {
                        buttons.push(
                            <Tooltip title="Löschen">
                                <Button
                                    type="danger"
                                    onClick={() => {
                                        Modal.confirm({
                                            title: `Möchten Sie "${scheme.name}" wirklich löschen?`,
                                            okText: 'ja',
                                            okType: 'danger',
                                            cancelText: 'nein',
                                            onOk: () => {
                                                this.props.deleteScheme(
                                                    this.props.token,
                                                    scheme.id
                                                );
                                            }
                                        });
                                    }}
                                >
                                    <Icon type="delete"/>
                                </Button>
                            </Tooltip>
                        );
                    }

                    if (!scheme.activatedAt && !scheme.deactivatedAt) {
                        buttons.push(
                            <Tooltip title="Aktivieren">
                                <Button
                                    onClick={() => {
                                        this.props.activateScheme(
                                            this.props.token,
                                            scheme.id
                                        );
                                    }}
                                >
                                    <Icon style={{color: 'green'}} type="global"/>
                                </Button>
                            </Tooltip>
                        );
                    }

                    if (scheme.activatedAt && !scheme.deactivatedAt) {
                        buttons.push(
                            <Tooltip title="Deaktivieren">
                                <Button
                                    onClick={() => {
                                        this.props.deactivateScheme(
                                            this.props.token,
                                            scheme.id
                                        );
                                    }}
                                >
                                    <Icon style={{color: 'red'}} type="global"/>
                                </Button>
                            </Tooltip>
                        );
                    }

                    buttons.push(
                        <Tooltip title="Bearbeiten">
                            <Link to={`/scheme/form/${scheme.id}`}>
                                <Button>
                                    <Icon type="edit"/>
                                </Button>
                            </Link>
                        </Tooltip>
                    );

                    buttons.push(
                        <Tooltip title="Kopieren">
                            <Button
                                onClick={() => {
                                    this.props.cloneScheme(
                                        this.props.token,
                                        scheme.id
                                    );
                                }}
                            >
                                <Icon type="copy" />
                            </Button>
                        </Tooltip>
                    );

                    return (
                        <span>
                            {buttons.map((button, index) => {
                                return (
                                    <span key={index}>
                                        <span style={{float: 'right'}}>{button}</span>
                                        <span style={{float: 'right'}}>&nbsp;</span>
                                    </span>
                                );
                            })}
                        </span>
                    );
                }
            }
        ];

        return (
            <div>
                {this.renderHeader()}
                <Table dataSource={this.props.schemes.slice().reverse()} columns={columns} pagination={false}
                       rowKey="id"/>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        schemes: get(state, 'schemesList', []),
        token: get(state, 'auth.token', null),
    } as Partial<IProps>),
    (dispatch) => ({
        getSchemeList: (token: string) => {
            dispatch(getSchemeList(token)).then(noop).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Schemen konnten nicht geladen werden.'
                });
            });
        },
        deleteScheme: (token: string, id: number) => {
            dispatch(deleteScheme(token, id)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Das Schema wurde gelöscht.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Das Schema konnte nicht gelöscht werden.'
                });
            });
        },
        activateScheme: (token: string, id: number) => {
            dispatch(activateScheme(token, id)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Das Schema wurde aktiviert.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Das Schema konnte nicht aktiviert werden.'
                });
            });
        },
        deactivateScheme: (token: string, id: number) => {
            dispatch(deactivateScheme(token, id)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Das Schema wurde deaktiviert.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Das Schema konnte nicht deaktiviert werden.'
                });
            });
        },
        cloneScheme: (token: string, id: number) => {
            dispatch(cloneScheme(token, id)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Das Schema wurde geklont.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Das Schema konnte nicht geklont werden.'
                });
            });
        },
    } as Partial<IProps>)
)(
    List
);
