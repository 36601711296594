import * as React from 'react';
import { find, get, assign } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import IUser from '../../model/user/IUser';
import setUser from '../../creator/user/setUser';

export interface IProps extends RouteComponentProps<{ id: string }> {
    originalUser?: IUser;
    user?: IUser;
    setUser: (user: IUser) => void;
}

export interface IState {
    redirect: boolean;
}

export default function edit<T>(WrappedComponent: React.ComponentType<T>): React.ComponentType<T> {
    class EditWrapper extends React.Component<T & IProps, IState> {
        constructor(props: T & IProps, context: {}) {
            super(props, context);

            this.state = {
                redirect: !props.originalUser
            };
        }

        public componentWillReceiveProps(nextProps: Readonly<T & IProps>): void {
            if (this.props.user && !nextProps.user) {
                this.setState({
                    redirect: true
                });
            }
        }

        public componentWillMount(): void {
            if (!this.props.originalUser) {
                return;
            }

            this.props.setUser(
                assign({}, this.props.originalUser),
            );
        }

        public componentWillUnmount(): void {
            this.props.setUser(null);
        }

        public render() {
            if (this.state.redirect) {
                return (
                    <Redirect to="/user"/>
                );
            } else if (!this.props.user) {
                return null;
            } else {
                return <WrappedComponent {...this.props}/>
            }
        }
    }

    return connect(
        (state, ownProps: IProps) => ({
            originalUser: find(
                get(state, 'users', []),
                {
                    id: parseInt(ownProps.match.params.id)
                }
            ) || null,
            user: get(state, 'user', null)
        } as Partial<IProps>),
        (dispatch) => ({
            setUser: (user: IUser) => {
                dispatch(setUser(user));
            }
        })
    )(EditWrapper) as any;
}
