import { assign, get, findIndex } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IEntityType from '../../../model/scheme/IEntityType';
import IScheme from '../../../model/scheme/IScheme';

export default function updateEntityType(entityType: IEntityType): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            const index = findIndex(scheme.entityTypes, {
                id: entityType.id
            });

            let buffer: IScheme = assign({}, scheme);

            buffer.entityTypes[index] = entityType;

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}