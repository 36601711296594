import { assign, get, filter, forEach, map } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../action/constants';
import IScheme from '../../model/scheme/IScheme';
import IEntity from '../../model/scheme/IEntity';
import IReference from '../../model/scheme/IReference';
import IEntityType from '../../model/scheme/IEntityType';
import IPriceList from '../../model/scheme/IPriceList';
import IPrice from '../../model/scheme/IPrice';

export default function cleanupEntityReferences(): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');
            let entityTypeIds: string[] = [];
            let entityIds: string[] = [];

            forEach(scheme.entityTypes, (entityType: IEntityType) => {
                entityTypeIds.push(entityType.id);
                forEach(entityType.e, (entity: IEntity) => {
                    entityIds.push(entity.id);
                });
            });

            let buffer: IScheme = assign({}, scheme);

            forEach(buffer.references, (reference: IReference, key) => {
                buffer.references[key].rETA = filter(reference.rETA, (entityType: string) => {
                    return entityTypeIds.indexOf(entityType) !== -1;
                });

                buffer.references[key].rETB = filter(reference.rETB, (entityType: string) => {
                    return entityTypeIds.indexOf(entityType) !== -1;
                });

                buffer.references[key].rEA = filter(reference.rEA, (entity: string) => {
                    return entityIds.indexOf(entity) !== -1
                });

                buffer.references[key].rEB = filter(reference.rEB, (entity: string) => {
                    return entityIds.indexOf(entity) !== -1;
                });
            });

            buffer.references = filter(buffer.references, (reference: IReference) => {
                let empty = 0;
                if(reference.rEA.length === 0) {
                    empty++;
                }
                if(reference.rEB.length === 0) {
                    empty++;
                }
                if(reference.rETA.length === 0) {
                    empty++;
                }
                if(reference.rETB.length === 0) {
                    empty++;
                }

                return empty <= 2;
            });

            buffer.priceLists = filter(buffer.priceLists, (priceList: IPriceList) => {
                if (entityTypeIds.indexOf(priceList.rET) === -1) {
                    return false;
                } else {
                    return true;
                }
            });

            buffer.priceLists = map(buffer.priceLists, (priceList: IPriceList) => {
                return assign({}, priceList, {
                    prices: filter(priceList.prices, (price: IPrice) => {
                        if (price.rE && entityIds.indexOf(price.rE) === -1) {
                            return false;
                        } else if (entityIds.indexOf(price.e) === -1) {
                            return false;
                        } else {
                            return true;
                        }
                    })
                });
            });

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}