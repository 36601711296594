import * as React from 'react';
import { csvParseRows, csvFormatRows } from 'd3-dsv';
import { v1 } from 'uuid';
import { assign, find, filter, forEach, map } from 'lodash';
import { Button, Form as AntForm, Select, Input, Upload, Icon, InputNumber, notification } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import IPriceList from '../../../../model/scheme/IPriceList';
import IEntityType from '../../../../model/scheme/IEntityType';
import IPrice from '../../../../model/scheme/IPrice';

export interface IProps extends FormComponentProps {
    priceList: IPriceList;
    entityTypes: IEntityType[];
    onChange: (priceList: IPriceList) => void;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Form extends React.Component<IProps, {}> {
    public render() {
        const {getFieldValue, getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;
        const nameError = getFieldError('name');
        const relatedEntityTypeError = getFieldError('rET');
        const pricesError = getFieldError('prices');
        const rolesError = getFieldError('roles');

        return (
            <AntForm onSubmit={this.onSubmit.bind(this)}>
                <AntForm.Item
                    validateStatus={nameError ? 'error' : 'success'}
                    help={nameError || ''}
                    label={'Name'}
                >
                    {getFieldDecorator('name', {
                        initialValue: this.props.priceList.name,
                        rules: [{required: true, message: 'Bitte Name eingeben.'}],
                    })(
                        <Input
                            onChange={(e) => {
                                setFieldsValue({
                                    name: e.target.value
                                })
                            }}
                        />
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={relatedEntityTypeError ? 'error' : 'success'}
                    help={relatedEntityTypeError || ''}
                    label={'Kopfzeile'}
                >
                    {getFieldDecorator('rET', {
                        initialValue: this.props.priceList.rET,
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{required: true, message: 'Bitte Typ auswählen.'}],
                    })(
                        <Select onChange={(value: string) => {
                            setFieldsValue({
                                rET: value
                            })
                        }}>{this.props.entityTypes.map((entityType) => {
                            return (
                                <Select.Option
                                    key={entityType.id}
                                    value={entityType.id}
                                >{entityType.n}</Select.Option>
                            );
                        })}</Select>
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={pricesError ? 'error' : 'success'}
                    help={pricesError || ''}
                    label={'CSV'}
                >
                    {getFieldDecorator('prices', {
                        initialValue: this.props.priceList.prices,
                        rules: [{required: true, message: 'Bitte CSV hochladen.'}],
                    })(
                        <div>
                            <Upload.Dragger
                                action={null}
                                multiple={false}
                                showUploadList={false}
                                accept="text/csv"
                                customRequest={(options) => {
                                    const {file, onSuccess, onError} = options;
                                    const reader = new FileReader();
                                    reader.readAsText(file);
                                    reader.onload = () => {
                                        try {
                                            this.props.form.setFieldsValue({
                                                prices: this.handleCsvUpload(reader.result)
                                            });

                                            notification['success']({
                                                message: 'Erfolgreich',
                                                description: 'Die Datei wurde erfolgreich hochgeladen.'
                                            });

                                            onSuccess();
                                        } catch (e) {
                                            this.props.form.setFieldsValue({
                                                prices: null
                                            });

                                            notification['error']({
                                                message: 'Fehler',
                                                description: 'Die Datei konnte nicht hochgeladen werden.'
                                            });

                                            onError();
                                        }
                                    };
                                }}
                                disabled={!getFieldValue('rET')}
                            >
                                <Button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        this.handleCsvDownload();
                                    }}
                                    disabled={!getFieldValue('rET')}
                                >
                                    <Icon type="download"/> Download
                                </Button>
                                <Button
                                    disabled={!getFieldValue('rET')}
                                >
                                    <Icon type="upload"/> Upload
                                </Button>
                            </Upload.Dragger>
                        </div>
                    )}
                </AntForm.Item>
                <AntForm.Item
                    validateStatus={rolesError ? 'error' : 'success'}
                    help={rolesError || ''}
                    label="Rollen"
                >
                    {getFieldDecorator('roles', {
                        initialValue: this.props.priceList.roles,
                        validateTrigger: ['onChange', 'onBlur'],
                        rules: [{required: true, message: 'Bitte Rollen auswählen.'}],
                    })(
                        <Select mode={'multiple'} onChange={(value: string) => {
                            setFieldsValue({
                                roles: value
                            })
                        }}>
                            <Select.Option value="ROLE_USER">Benutzer</Select.Option>
                            <Select.Option value="ROLE_ADMIN">Administrator</Select.Option>
                        </Select>
                    )}
                </AntForm.Item>
                <AntForm.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        disabled={hasErrors(getFieldsError())}
                    >
                        übernehmen
                    </Button>
                </AntForm.Item>
            </AntForm>
        );
    }

    protected onSubmit(e: React.FormEvent<any>): void {
        e.preventDefault();

        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onChange(
                    assign({}, this.props.priceList, {
                        name: values.name,
                        rET: values.rET,
                        prices: values.prices,
                        roles: values.roles,
                    })
                );
            }
        });
    }

    protected extractIdFromText(text: string): string {
        const lastIndexOfBracket = text.lastIndexOf('(');

        if (lastIndexOfBracket === -1) {
            throw new Error('Text does not include bracket.');
        }

        const buffer = text.slice(lastIndexOfBracket);
        const regExp = /\(([^)]+)\)/;
        const result = buffer.match(regExp);

        if (!result) {
            throw new Error('Text does not match pattern.');
        }

        return result[1];
    }

    protected handleCsvUpload(content: string): IPrice[] {
        try {
            const data = csvParseRows(content);

            let prices: IPrice[] = [];

            for (let col = 1; col < data[0].length; col++) {
                prices.push({
                    id: `frontend-${v1()}`,
                    rE: null,
                    e: this.extractIdFromText(data[0][col]),
                    p: parseInt(data[1][col]) || 0
                });
            }

            for (let line = 2; line < data.length; line++) {
                for (let col = 1; col < data[line].length; col++) {
                    prices.push({
                        id: `frontend-${v1()}`,
                        rE: this.extractIdFromText(data[0][col]),
                        e: this.extractIdFromText(data[line][0]),
                        p: parseInt(data[line][col])
                    });
                }
            }

            return prices;
        } catch (e) {
            throw new Error('Invalid format.');
        }
    }

    protected handleCsvDownload(): void {
        const prices: IPrice[] = this.props.form.getFieldValue('prices');
        const mime = 'data:text/csv;charset=utf-8,';
        const headEntityType = find(this.props.entityTypes, {
            id: this.props.form.getFieldValue('rET')
        });
        const bodyEntityTypes = filter(this.props.entityTypes, (entityType) => {
            return entityType.id !== headEntityType.id;
        });
        let lines: string[][] = [];

        let header = map(headEntityType.e, (entity) => {
            return `${entity.n} (${entity.id})`;
        });
        header.unshift('');
        lines.push(header);

        let basePrices: any = [];
        forEach(headEntityType.e, (headEntity) => {
            let price = find(prices, {
                e: headEntity.id,
                rE: null
            });

            basePrices.push(price ? price.p : 0);
        });
        basePrices.unshift('Grundpreis');
        lines.push(basePrices);

        forEach(bodyEntityTypes, (entityType) => {
            forEach(entityType.e, (entity) => {
                let entityBuffer: any[] = [];

                forEach(headEntityType.e, (headEntity) => {
                    let price = find(prices, {
                        e: entity.id,
                        rE: headEntity.id
                    });

                    entityBuffer.push(price ? price.p : 0);
                });

                entityBuffer.unshift(`${entity.n} (${entity.id})`);
                lines.push(entityBuffer);
            });
        });

        let downloadLink = document.createElement('a');
        let url = encodeURI(mime + csvFormatRows(lines));
        downloadLink.href = url;
        downloadLink.download = 'Preisliste.csv';
        document.body.appendChild(downloadLink);
        downloadLink.click();

        setTimeout(() => {
            document.body.removeChild(downloadLink);
            window.URL.revokeObjectURL(url);
        }, 100);
    }
}

export default AntForm.create()(Form);
