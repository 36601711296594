import * as React from 'react';
import { Input, Button, Form } from 'antd';
import { Redirect } from 'react-router-dom';
import { FormComponentProps } from 'antd/lib/form';

export interface IProps extends FormComponentProps {
    onLogin: (userName: string, password: string) => void;
    anonymous: boolean;
    isTimedOut: boolean;
}

function hasErrors(fieldsError: any) {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class Login extends React.Component<IProps, {}> {
    public render() {
        if (!this.props.anonymous && !this.props.isTimedOut) {
            return <Redirect to="/"/>
        }

        const {getFieldDecorator, getFieldsError, getFieldError, setFieldsValue} = this.props.form;
        const userNameError = getFieldError('userName');
        const passwordError = getFieldError('password');

        return (
            <div>
                <h1>Anmeldung</h1>
                <Form onSubmit={(e) => {
                    e.preventDefault();

                    this.props.form.validateFields((err, values) => {
                        if (!err) {
                            this.props.onLogin(values.userName, values.password)
                        }
                    });
                }}>
                    <Form.Item
                        validateStatus={userNameError ? 'error' : 'success'}
                        help={userNameError || ''}
                        label="Benutzer"
                    >
                        {getFieldDecorator('userName', {
                            rules: [{required: true, message: 'Bitte Benutzer eingeben.'}],
                        })(
                            <Input
                                onChange={(e) => {
                                    setFieldsValue({
                                        userName: e.target.value
                                    })
                                }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item
                        validateStatus={passwordError ? 'error' : 'success'}
                        help={passwordError || ''}
                        label="Passwort"
                    >
                        {getFieldDecorator('password', {
                            rules: [{required: true, message: 'Bitte Passwort eingeben.'}],
                        })(
                            <Input
                                type="password"
                                onChange={(e) => {
                                    setFieldsValue({
                                        password: e.target.value
                                    })
                                }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={hasErrors(getFieldsError())}
                        >
                            anmelden
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    }
}

export default Form.create()(Login);