import * as React from 'react';
import { Button, Row, Col, Table, Icon, Modal } from 'antd';
import { noop } from 'lodash';
import IScheme from '../../../../model/scheme/IScheme';
import EntityTypeForm from './EntityTypeForm';
import EntityForm from './EntityForm';
import IEntityType from '../../../../model/scheme/IEntityType';
import IEntity from '../../../../model/scheme/IEntity';

export interface IProps {
    token: string;
    scheme: IScheme;
    onAddEntityType: (entityType: IEntityType) => void;
    onUpdateEntityType: (entityType: IEntityType) => void;
    onDeleteEntityType: (entityType: IEntityType) => void;
    onAddEntity: (entityType: IEntityType, entity: IEntity) => void;
    onUpdateEntity: (entityType: IEntityType, entity: IEntity) => void;
    onDeleteEntity: (entityType: IEntityType, entity: IEntity) => void;
}

export interface IState {
    entityTypeFormVisible: boolean;
    entityFormVisible: boolean;
    entityType: IEntityType;
    entity: IEntity;
}

class Index extends React.Component<IProps, IState> {
    constructor(props: IProps, context: {}) {
        super(props, context);

        this.state = {
            entityTypeFormVisible: false,
            entityFormVisible: false,
            entityType: null,
            entity: null,
        };
    }

    public renderEntityTypeForm() {
        return this.state.entityType && this.state.entityTypeFormVisible && (
            <Modal
                title={this.state.entityType.n ?
                    `${this.state.entityType.n} bearbeiten` :
                    'Entitäten-Typ erstellen'
                }
                visible={true}
                onOk={noop}
                onCancel={() => {
                    this.setState({
                        entityTypeFormVisible: false,
                        entityType: null
                    });
                }}
                closable={true}
                destroyOnClose={true}
                footer={null}
            >
                <EntityTypeForm
                    entityType={this.state.entityType}
                    onChange={(entityType) => {
                        if (entityType.id) {
                            this.props.onUpdateEntityType(entityType);
                        } else {
                            this.props.onAddEntityType(entityType);
                        }

                        this.setState({
                            entityTypeFormVisible: false,
                            entityType: null
                        });
                    }}
                    disabled={!!this.props.scheme.activatedAt}
                />
            </Modal>
        );
    }

    public renderEntityForm() {
        return this.state.entity && this.state.entityFormVisible && (
            <Modal
                title={this.state.entity.n ?
                    `${this.state.entity.n} bearbeiten` :
                    'Entität erstellen'
                }
                visible={true}
                onOk={noop}
                onCancel={() => {
                    this.setState({
                        entityFormVisible: false
                    });
                }}
                closable={true}
                destroyOnClose={true}
                footer={null}
            >
                <EntityForm
                    token={this.props.token}
                    entity={this.state.entity}
                    entityFields={this.state.entityType.eF}
                    onChange={(entity) => {
                        if (entity.id) {
                            this.props.onUpdateEntity(this.state.entityType, entity);
                        } else {
                            this.props.onAddEntity(this.state.entityType, entity);
                        }

                        this.setState({
                            entityFormVisible: false,
                            entity
                        });
                    }}
                    disabled={!!this.props.scheme.activatedAt}
                />
            </Modal>
        );
    }

    public renderEntityTypeTableHeader() {
        return (
            <h2>
                Entitäten-Typen
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            entityTypeFormVisible: true,
                            entityType: {
                                id: null,
                                n: null,
                                eF: [],
                                e: []
                            }
                        });
                    }}
                    style={{float: 'right'}}
                    disabled={!!this.props.scheme.activatedAt}
                >
                    <Icon type="plus"/>
                </Button>
            </h2>
        );
    }

    public renderEntityTypeTable() {
        const entityTypeColumns = [
            {
                title: 'Name',
                dataIndex: 'n',
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, entityType: IEntityType) => {
                    return (
                        <span>
                            <Button
                                type="danger"
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Möchten Sie "${entityType.n}" wirklich löschen?`,
                                        okText: 'ja',
                                        okType: 'danger',
                                        cancelText: 'nein',
                                        onOk: () => {
                                            this.props.onDeleteEntityType(entityType);

                                            if (this.state.entityType && this.state.entityType.id === entityType.id) {
                                                this.setState({
                                                    entityType: null
                                                });
                                            }
                                        }
                                    });
                                }}
                                style={{float: 'right'}}
                                disabled={!!this.props.scheme.activatedAt}
                            >
                                <Icon type="delete"/>
                            </Button>
                            <span style={{float: 'right'}}>&nbsp;</span>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        entityTypeFormVisible: true,
                                        entityType,
                                    });
                                }}
                                style={{float: 'right'}}
                            >
                                <Icon type="edit"/>
                            </Button>
                        </span>
                    );
                }
            }
        ];

        return (
            <Table rowKey="id"
                   dataSource={this.props.scheme.entityTypes.slice().reverse()}
                   columns={entityTypeColumns}
                   pagination={false}
                   rowSelection={{
                       selectedRowKeys: this.state.entityType ? [this.state.entityType.id] : [],
                       type: 'radio',
                       onSelect: (entityType: IEntityType) => {
                           this.setState({
                               entityType
                           });
                       }
                   }}
            />
        );
    }

    public renderEntityTableHeader() {
        return this.state.entityType && (
            <h2>
                Entitäten
                <Button
                    type="primary"
                    onClick={() => {
                        this.setState({
                            entityFormVisible: true,
                            entity: {
                                id: null,
                                d: {},
                                n: null,
                                t: []
                            }
                        });
                    }}
                    style={{float: 'right'}}
                    disabled={!!this.props.scheme.activatedAt}
                >
                    <Icon type="plus"/>
                </Button>
            </h2>
        );
    }

    public renderEntityTable() {
        const entityColumns = [
            {
                title: 'Name',
                dataIndex: 'n',
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, entity: IEntity) => {
                    return (
                        <span>
                            <Button
                                type="danger"
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Möchten Sie "${entity.n}" wirklich löschen?`,
                                        okText: 'ja',
                                        okType: 'danger',
                                        cancelText: 'nein',
                                        onOk: () => {
                                            this.props.onDeleteEntity(this.state.entityType, entity);

                                            if (this.state.entity && this.state.entity.id === entity.id) {
                                                this.setState({
                                                    entity: null
                                                });
                                            }
                                        }
                                    });
                                }}
                                style={{float: 'right'}}
                                disabled={!!this.props.scheme.activatedAt}
                            >
                                <Icon type="delete"/>
                            </Button>
                            <span style={{float: 'right'}}>&nbsp;</span>
                            <Button
                                onClick={() => {
                                    this.setState({
                                        entityFormVisible: true,
                                        entity,
                                    });
                                }}
                                style={{float: 'right'}}
                            >
                                <Icon type="edit"/>
                            </Button>
                        </span>
                    );
                }
            }
        ];

        return this.state.entityType && (
            <Table rowKey="id"
                   dataSource={this.state.entityType.e.slice().reverse()}
                   columns={entityColumns}
                   pagination={false}
            />
        );
    }

    public render() {
        return (
            <div>
                {this.renderEntityTypeForm()}
                {this.renderEntityForm()}
                <Row gutter={32}>
                    <Col span={12}>
                        {this.renderEntityTypeTableHeader()}
                        {this.renderEntityTypeTable()}
                    </Col>
                    <Col span={12}>
                        {this.renderEntityTableHeader()}
                        {this.renderEntityTable()}
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Index;
