import * as React from 'react';
import { connect } from 'react-redux';
import { get, noop } from 'lodash';
import { Table, Icon, Button, notification, Modal, Tooltip } from 'antd';
import getConfigurations from '../../creator/configuration/getConfigurations';
import IConfiguration from '../../model/configuration/IConfiguration';
import deleteConfiguration from '../../creator/configuration/delete';
import activateConfiguration from '../../creator/configuration/activate';
import deactivateConfiguration from '../../creator/configuration/deactivate';

export interface IProps {
    configurations: IConfiguration[];
    token: string;
    getConfigurations: (token: string) => void;
    deleteConfiguration: (token: string, id: string) => void;
    activateConfiguration: (token: string, id: string) => void;
    deactivateConfiguration: (token: string, id: string) => void;
}

class List extends React.Component<IProps, {}> {
    public componentWillMount(): void {
        this.props.getConfigurations(
            this.props.token
        );
    }

    public renderHeader() {
        return (
            <h1>
                Konfigurationen
            </h1>
        );
    }

    public render() {
        const columns = [
            {
                title: 'Schema',
                dataIndex: 'scheme.name'
            },
            {
                title: 'Benutzer',
                dataIndex: 'user.userName'
            },
            {
                title: 'Name',
                dataIndex: 'name'
            },
            {
                title: 'B. 1 Vorname',
                dataIndex: 'firstName1'
            },
            {
                title: 'B. 1 Nachname',
                dataIndex: 'lastName1'
            },
            {
                title: 'B. 2 Vorname',
                dataIndex: 'firstName2'
            },
            {
                title: 'B. 2 Nachname',
                dataIndex: 'lastName2'
            },
            {
                title: 'Datum',
                dataIndex: 'created'
            },
            {
                title: '',
                key: 'actions',
                render: (text: string, configuration: IConfiguration) => {
                    let buttons: React.ReactElement<any>[] = [];

                    buttons.push(
                        <Tooltip title="Löschen">
                            <Button
                                type="danger"
                                onClick={() => {
                                    Modal.confirm({
                                        title: `Möchten Sie "${configuration.name}" wirklich löschen?`,
                                        okText: 'ja',
                                        okType: 'danger',
                                        cancelText: 'nein',
                                        onOk: () => {
                                            this.props.deleteConfiguration(
                                                this.props.token,
                                                configuration.id
                                            );
                                        }
                                    });
                                }}
                            >
                                <Icon type="delete"/>
                            </Button>
                        </Tooltip>
                    );

                    if (configuration.isDefault) {
                        buttons.push(
                            <Tooltip title="Deaktivieren">
                                <Button
                                    onClick={() => {
                                        this.props.deactivateConfiguration(
                                            this.props.token,
                                            configuration.id
                                        );
                                    }}
                                >
                                    <Icon style={{color: 'red'}} type="global"/>
                                </Button>
                            </Tooltip>
                        );
                    } else {
                        buttons.push(
                            <Tooltip title="Aktivieren">
                                <Button
                                    onClick={() => {
                                        this.props.activateConfiguration(
                                            this.props.token,
                                            configuration.id
                                        );
                                    }}
                                >
                                    <Icon style={{color: 'green'}} type="global"/>
                                </Button>
                            </Tooltip>
                        );
                    }

                    return (
                        <span>
                            {buttons.map((button, index) => {
                                return (
                                    <span key={index}>
                                        <span style={{float: 'right'}}>{button}</span>
                                        <span style={{float: 'right'}}>&nbsp;</span>
                                    </span>
                                );
                            })}
                        </span>
                    );
                }
            }
        ];

        return (
            <div>
                {this.renderHeader()}
                <Table dataSource={this.props.configurations.slice().reverse()} columns={columns} pagination={false}
                       rowKey="id"/>
            </div>
        );
    }
}

export default connect(
    (state) => ({
        configurations: get(state, 'configurations', []),
        token: get(state, 'auth.token', null),
    } as Partial<IProps>),
    (dispatch) => ({
        getConfigurations: (token: string) => {
            dispatch(getConfigurations(token)).then(noop).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Konfigurationen konnten nicht geladen werden.'
                });
            });
        },
        deleteConfiguration: (token: string, id: string) => {
            dispatch(deleteConfiguration(token, id)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Konfiguration wurde gelöscht.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Konfiguration konnte nicht gelöscht werden.'
                });
            });
        },
        activateConfiguration: (token: string, id: string) => {
            dispatch(activateConfiguration(token, id)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Konfiguration wurde als Standard-Konfiguration aktiviert.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Konfiguration konnte nicht als Standard-Konfiguration aktiviert werden.'
                });
            });
        },
        deactivateConfiguration: (token: string, id: string) => {
            dispatch(deactivateConfiguration(token, id)).then(() => {
                notification['success']({
                    message: 'Erfolgreich',
                    description: 'Die Konfiguration wurde als Standard-Konfiguration deaktiviert.'
                });
            }).catch(() => {
                notification['error']({
                    message: 'Fehler',
                    description: 'Die Konfiguration konnte nicht als Standard-Konfiguration deaktiviert werden.'
                });
            });
        },
    } as Partial<IProps>)
)(
    List
);