import { assign, get, findIndex, filter } from 'lodash';
import { ThunkAction } from 'redux-thunk';
import { types } from '../../../action/constants';
import IScheme from '../../../model/scheme/IScheme';
import IWorkflowStep from '../../../model/scheme/IWorkflowStep';
import IWorkflowSubStep from '../../../model/scheme/IWorkflowSubStep';

export default function moveSubStepForward(workflowStep: IWorkflowStep, workflowSubStep: IWorkflowSubStep): ThunkAction<Promise<void>, {}, {}> {
    return (dispatch, getState, extraArg) => {
        return new Promise((resolve) => {
            const scheme: IScheme = get(getState(), 'scheme');

            const workflowStepIndex = findIndex(scheme.workflowSteps, {
                id: workflowStep.id
            });

            const workflowSubStepIndex = findIndex(scheme.workflowSteps[workflowStepIndex].sSteps, {
                id: workflowSubStep.id
            });

            let buffer: IScheme = assign({}, scheme);

            if (workflowSubStepIndex > 0) {
                buffer.workflowSteps[workflowStepIndex].sSteps[workflowSubStepIndex] = null;
                buffer.workflowSteps[workflowStepIndex].sSteps.splice(workflowSubStepIndex - 1, 0, workflowSubStep);
                buffer.workflowSteps[workflowStepIndex].sSteps = filter(buffer.workflowSteps[workflowStepIndex].sSteps, (subStep) => {
                    return subStep !== null;
                });
            }

            dispatch({
                type: types.set_scheme,
                scheme: buffer
            });

            resolve();
        });
    }
}